function getSizeRegex() {
  return /(\d+x\d+)|fluid|interstitial/;
}

export const sizesArrayToStringArray = sizes =>
  sizes ? sizes.map(values => (Array.isArray(values) ? values.join('x') : values)) : [];

export const displayNamesToValues = (displayNames = []) => {
  return displayNames.map(displayName => {
    if (displayName === 'fluid') {
      return {
        width: null,
        height: null,
        fluid: true,
      };
    }

    if (displayName === 'interstitial') {
      return {
        width: null,
        height: null,
        fluid: false,
        interstitial: true,
      };
    }

    let [, width, height] = displayName.match(/(\d+)x(\d+)/);

    return {
      width: Number(width),
      height: Number(height),
      fluid: false,
      displayName,
    };
  });
};

export const createFormatTag = ({ term }) => {
  if (!getSizeRegex().test(term)) {
    // Return null to disable tag creation
    // Filter for format numberxnumber input
    return null;
  }

  return {
    id: term,
    text: term,
  };
};

export function createSizeTag({ term }) {
  if (!getSizeRegex().test(term)) {
    // Return null to disable tag creation
    // Filter for format numberxnumber input
    return null;
  }

  return {
    id: term,
    text: term,
  };
}

export function createSlotTag({ term }) {
  return {
    id: term,
    text: term,
  };
}
