export const settingsMetadata = {
  adformats: {
    title: 'Ad Formats',
    icon: ['far', 'window-maximize'],
    anchor: '#formats',
    domId: 'formats',
    description:
      'Manage the formats used by Adshim. Add or edit existing formats. Change things like sizes, sizemappings, out-of-page flag.',
    color: 'indigo',
  },
  prebid: {
    title: 'Prebid Settings',
    icon: ['fas', 'gavel'],
    anchor: '#prebid',
    domId: 'prebid',
    description:
      'Manage Prebid settings. Add or edit sizes and identifiers. Turn on or off bidders and change specific settings per bidder.',
    color: 'teal',
    subsettings: {
      prebidbidders: {
        title: 'Prebid Bidders',
        icon: ['fas', 'gavel'],
        anchor: '#prebid-bidders',
        domId: 'prebid-bidders',
        description: 'Turn on or off bidders and change specific settings per bidder.',
        color: 'teal',
      },
      prebidusersyncs: {
        title: 'Prebid User Sync',
        icon: ['fas', 'gavel'],
        anchor: '#prebid-userIds',
        domId: 'prebid-userIds',
        description: 'Turn on or off user syncs.',
        color: 'teal',
      },
      prebidslots: {
        title: 'Prebid Slots',
        icon: ['fas', 'columns'],
        anchor: '#prebidslots',
        domId: 'prebidslots',
        description:
          'Determine which ad slots should be used by Prebid. You can configure specific sizes and bidders per slot.',
        color: 'orange',
      },
    },
  },

  plugin: {
    title: 'Plugin Settings',
    icon: ['fas', 'plug'],
    anchor: '#plugins',
    domId: 'plugins',
    description:
      'Turn on and off different plugins like Adomik, Prebid, Cxense. All settings for these plugins are also managed here.',
    color: 'success',
  },
};

// {
//   adformats: {
//     items: [
//       {
//         title: 'Ad Formats',
//         icon: 'window-maximize',
//         link: () => {
//           document.getElementById('formats').scrollIntoView();
//         },
//       },
//       { title: 'Prebid', icon: 'gavel' },
//       { title: 'Prebid Slots', icon: 'columns' },
//     ],
//     icon: 'cogs',
//   },
// }
