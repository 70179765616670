import React from 'react';
import FormRow from '../../../../components/Form';
import { gql } from '@apollo/client';
import logo from '../../../../assets/images/plugins/ad-refresh.png';
import { FormikToggle as Toggle } from '../../../../components/Form/Toggle';
import AdRefreshTable from '../../../../components/Form/AdRefreshTable';
import { settingsRequiredWhenEnabled } from '../../../../utils';

export const id = 'refresh';
export const name = 'Ad Refresh';
export const description = 'Get more impressions by refreshing your ads.';
export const image = logo;

export const genSchema = Yup =>
  Yup.object()
    .default(undefined)
    .shape({
      timeInView: settingsRequiredWhenEnabled(Yup.number().label('Time ad needs to be in view')),
      enableForAll: settingsRequiredWhenEnabled(Yup.boolean().label('Enable for all ads')),
      timePerSlot: Yup.array()
        .default([])
        .of(
          Yup.object().shape({
            format: Yup.string(),
            slot: Yup.string(),
            seconds: Yup.number(),
          })
        )
        .label('Time per slot'),
    });

/**
 * @type {import('../../../../../types/settings').FormSection<{ blocklist: Array<string> }>}
 */
export const SettingsForm = ({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) => {
  const isEnabledForAll = value.enableForAll == null ? true : value.enableForAll;

  return (
    <>
      <FormRow
        name="timeInView"
        label="Time ad needs to be in view"
        value={value.timeInView}
        inheritedValue={inheritedValue.timeInView.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled || !isEnabledForAll}
        error={errors['timeInView']}
      />
      <FormRow
        as={Toggle}
        name="enableForAll"
        label="Enable for all ads?"
        value={true}
        inheritedChecked={inheritedValue.enableForAll.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors['enableForAll']}
        checked={isEnabledForAll}
      />

      <FormRow
        name="timePerSlot"
        label="Per slot settings"
        value={value.timePerSlot}
        inheritedValue={inheritedValue.timePerSlot.inheritedValue ?? undefined}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled || isEnabledForAll}
        error={errors['timePerSlot']}
        direction="column"
        as={AdRefreshTable}
      />
    </>
  );
};

export const FRAGMENT_PLUGIN_SETTINGS = {
  name: 'RefreshPluginSettings',
  fragment: gql`
    fragment RefreshPluginSettings on RefreshPlugin {
      timeInView {
        value
        inheritedValue
      }
      enableForAll {
        value
        inheritedValue
      }
      timePerSlot {
        value {
          format
          slot
          seconds
        }
        inheritedValue {
          format
          slot
          seconds
        }
      }
    }
  `,
};
