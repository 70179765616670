import React from 'react';
import FormRow from '../../../../components/Form';
import { settingsRequiredWhenEnabled } from '../../../../utils';
import { gql } from '@apollo/client';
import logo from '../../../../assets/images/plugins/rayn.png';
import { FormikToggle as Toggle } from '../../../../components/Form/Toggle';

export const id = 'rayn';
export const name = 'Rayn';
export const description =
  'Forget what you know. Hear the rumble. Follow the Rayndrops. Rayn. Digital Twin of a customer at scale.';
export const image = logo;

export const genSchema = yup =>
  yup.object().shape({
    connectionId: settingsRequiredWhenEnabled(yup.string().label('Connection id')),
  });

/**
 * @type {import('../../../../../types/settings').FormSection<{ id: string, url: string }>}
 */
export const SettingsForm = ({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) => {
  return (
    <>
      <FormRow
        name="connectionId"
        label="Connection id"
        value={value.connectionId}
        inheritedValue={inheritedValue.connectionId.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors['connectionId']}
      />

      <FormRow
        name="prebid"
        label="Enable prebid integration"
        as={Toggle}
        value={true}
        checked={value.prebid}
        inheritedChecked={inheritedValue.prebid.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        error={errors.prebid}
        disabled={!isEnabled}
      />
    </>
  );
};

export const FRAGMENT_PLUGIN_SETTINGS = {
  name: 'RaynPluginSettings',
  fragment: gql`
    fragment RaynPluginSettings on RaynPlugin {
      connectionId {
        value
        inheritedValue
      }
      prebid {
        value
        inheritedValue
      }
    }
  `,
};
