/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Fragment, useState } from 'react';
import { navigate } from 'gatsby';
import { gql } from '@apollo/client';
import { useAppContext } from '../../providers/App';
import { logout } from '../../services/auth';
import Logo from '../Logo';

// import logo from '../../assets/limitless/global_assets/images/logo_light.png';
// import avatar from '../../assets/limitless/global_assets/images/image.png';

function OrgIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" {...props}>
      <path d="M992 256h-32v-96a32 32 0 00-32-32h-96V32a32 32 0 00-64 0v96h-96a32 32 0 00-32 32v96h-32a32 32 0 00-32 32v160h192a64 64 0 0164 64v448h-64V544a32 32 0 00-32-32H352a32 32 0 00-32 32v416h-64V512a64 64 0 0164-64h128V160a32 32 0 00-32-32H32a32 32 0 00-32 32v832a32 32 0 0032 32h960a32 32 0 0032-32V288a32 32 0 00-32-32zM704 384h-64v-64h64v64zM320 192h64v64h-64v-64zm0 128h64v64h-64v-64zM192 192h64v64h-64v-64zm-64 576H64v-64h64v64zm0-128H64v-64h64v64zm0-128H64v-64h64v64zm0-128H64v-64h64v64zm0-128H64v-64h64v64zm64 64h64v64h-64v-64zm512 640H384V576h320v384zm128-576h-64v-64h64v64zm128 384h-64V640h64v128zm0-192h-64V448h64v128zm0-192h-64v-64h64v64zM448 640h64v64h-64v-64zm128 0h64v64h-64v-64zM448 768h64v64h-64v-64zm128 0h64v64h-64v-64z" />
    </svg>
  );
}

// function ArrowIcon(props) {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 1024" {...props}>
//       <path d="M495 389.2a35.7 35.7 0 0150.1 0 34.8 34.8 0 010 49.6l-200 196a35.7 35.7 0 01-50.1 0l-200.1-196a34.9 34.9 0 010-49.6 35.7 35.7 0 0150.1 0l175 160.7 175-160.7z" />
//     </svg>
//   );
// }

function logoutAction() {
  logout().then(() => {
    navigate('/login');
  });
}

function Header({ user }) {
  const { setOrganisation, organisation } = useAppContext();
  const [isImageLoaded, setImageLoaded] = useState(false);

  return (
    <div className="navbar navbar-expand-md navbar-dark fixed-top">
      <div className="navbar-brand">
        <h6 className="mb-0 font-weight-semibold" style={{ minWidth: 100 + 'px !important' }}>
          <span className="mr-2" style={{ display: 'inline-block', verticalAlign: 'text-bottom' }}>
            <Logo width="20px" height="20px" />
          </span>
          Adshim
        </h6>
      </div>

      <div className="d-md-none">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar-mobile"
        >
          <i className="icon-tree5" />
        </button>
        <button className="navbar-toggler sidebar-mobile-main-toggle" type="button">
          <i className="icon-paragraph-justify3" />
        </button>
        <button className="navbar-toggler sidebar-mobile-right-toggle" type="button">
          <svg viewBox="0 0 28 32">
            <path d="M4.22 10.201c-0.357-0.353-0.935-0.353-1.291 0-0.356 0.352-0.356 0.924 0 1.277l10.426 10.323c0.357 0.353 0.933 0.353 1.291 0l10.426-10.323c0.356-0.353 0.357-0.924 0-1.277s-0.936-0.353-1.291-0.001l-9.781 9.414-9.78-9.413z"></path>
          </svg>
        </button>
      </div>

      <div className="collapse navbar-collapse" id="navbar-mobile">
        <ul
          className="navbar-nav ml-auto"
          css={{ opacity: !isImageLoaded ? 0 : 1, transition: 'opacity 300ms linear' }}
        >
          <li className="nav-item dropdown dropdown-user">
            <a
              href="#"
              className="navbar-nav-link d-flex align-items-center dropdown-toggle"
              data-toggle="dropdown"
            >
              <img
                src={user.avatar}
                className="rounded-circle mr-2"
                width="32"
                height="34"
                alt=""
                onLoad={() => setImageLoaded(true)}
                onError={() => setImageLoaded(true)}
              />
              <span>{user.displayName}</span>
            </a>

            <div className="dropdown-menu dropdown-menu-right">
              <a href="#" className="dropdown-item">
                <i className="icon-user-plus" /> My profile
              </a>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <i className="icon-cog5" /> Account settings
              </a>
              <button type="button" className="dropdown-item" onClick={logoutAction}>
                <i className="icon-switch2" /> Logout
              </button>
            </div>
          </li>
        </ul>
        <ul
          className="navbar-nav"
          css={{
            display: user.organisations.length <= 1 ? 'none' : 'flex',
            opacity: !isImageLoaded ? 0 : 1,
            transition: 'opacity 300ms linear',
          }}
        >
          <li className="nav-item dropdown">
            <a
              href="#"
              className="navbar-nav-link d-flex align-items-center dropdown-toggle"
              data-toggle="dropdown"
            >
              <Fragment>
                <OrgIcon css={{ marginRight: '1rem', fill: 'currentColor' }} width="16" />
                <span>{organisation.name}</span>
              </Fragment>
            </a>

            <div className="dropdown-menu dropdown-menu-right">
              {user.organisations.map(organisation => {
                return (
                  <button
                    onClick={() => setOrganisation(organisation)}
                    className="dropdown-item"
                    key={organisation.id}
                  >
                    <OrgIcon css={{ marginRight: '1rem' }} width="16" /> {organisation.name}
                  </button>
                );
              })}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export const FRAGMENT_USER = {
  name: 'HeaderUserFragment',
  fragment: gql`
    fragment HeaderUserFragment on User {
      displayName
      avatar
      organisations {
        id
        name
        defaultSite {
          id
        }
        sites {
          id
          name
          logo
        }
      }
    }
  `,
};

export default Header;
