import React from 'react';
import { useMatch } from '@reach/router';

const withActiveState = Component => {
  function newComponent(props) {
    const match = useMatch(`${props.to.replace(/\/$/, '')}/*`);

    return <Component isActive={!!match} {...props} />;
  }

  newComponent.displayName = `withMatch${Component.displayName}`;

  return newComponent;
};

export default withActiveState;
