import React from 'react';
import FormRow from '../../../../components/Form';
import MappingTable from '../../../../components/Form/MappingTable';
import { settingsRequiredWhenEnabled } from '../../../../utils';
import { gql } from '@apollo/client';
import logo from '../../../../assets/images/bidders/rubicon.jpg';

export const id = 'rubicon';
export const name = 'Rubicon Project';
export const mutationId = 'Rubicon';
export const description =
  'Rubicon Project is a leading technology company automating the buying and selling of advertising. Relentless in its efforts for innovation, Rubicon Project has engineered one of the largest real-time cloud and Big Data computing systems, processing trillions of transactions within milliseconds each month.';
export const image = logo;

export const genSchema = Yup =>
  Yup.object()
    .default(undefined)
    .shape({
      accountId: settingsRequiredWhenEnabled(Yup.string().label('Account ID')),
      // video: Yup.boolean().label('Video Instream'),
      // analytics: Yup.boolean().label('Prebid Analytics'),
      siteId: settingsRequiredWhenEnabled(
        Yup.array()
          .of(
            Yup.object().shape({
              targeting: Yup.array().of(
                Yup.object().shape({
                  key: Yup.string(),
                  value: Yup.mixed(),
                })
              ),
              name: Yup.string(),
              value: Yup.mixed(),
            })
          )
          .label('Site ID')
      ),
      zoneId: settingsRequiredWhenEnabled(
        Yup.array()
          .of(
            Yup.object().shape({
              targeting: Yup.array().of(
                Yup.object().shape({
                  key: Yup.string(),
                  value: Yup.mixed(),
                })
              ),
              name: Yup.string(),
              value: Yup.mixed(),
            })
          )
          .label('Zone ID')
      ),
    });

/**
 * @type {import('../../../../../types/settings').FormSection<{}>}
 */
export const SettingsForm = function RubiconSettingsForm({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) {
  return (
    <>
      <FormRow
        name="accountId"
        label="Account ID"
        value={value.accountId}
        inheritedValue={inheritedValue.accountId.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        error={errors.accountId}
        disabled={!isEnabled}
      />
      {/* <FormRow
        name="video"
        label="Video Instream"
        as={Toggle}
        value={true}
        checked={value.video}
        inheritedChecked={inheritedValue.video.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        error={errors.video}
        disabled={!isEnabled}
      /> */}
      {/* <FormRow
        name="analytics"
        label="Prebid Analytics"
        as={Toggle}
        value={true}
        checked={value.analytics}
        inheritedChecked={inheritedValue.analytics}
        showInheritanceBadge={showInheritanceBadge}
        error={errors.analytics}
        disabled={!isEnabled}
      /> */}
      <FormRow
        name="siteId"
        label="Site ID"
        value={value.siteId}
        inheritedValue={inheritedValue.siteId.inheritedValue ?? undefined}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors.siteId}
        direction="column"
        as={MappingTable}
      />
      <FormRow
        name="zoneId"
        label="Zone ID"
        value={value.zoneId}
        inheritedValue={inheritedValue.zoneId.inheritedValue ?? undefined}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors.zoneId}
        direction="column"
        as={MappingTable}
      />
    </>
  );
};

export const FRAGMENT_BIDDER_SETTINGS = {
  name: 'RubiconBidderSettings',
  fragment: gql`
    fragment RubiconBidderSettings on RubiconBidder {
      accountId {
        value
        inheritedValue
      }
      zoneId {
        value {
          name
          value
          targeting {
            key
            value
          }
        }
        inheritedValue {
          name
          value
          targeting {
            key
            value
          }
        }
      }
      siteId {
        value {
          name
          value
          targeting {
            key
            value
          }
        }
        inheritedValue {
          name
          value
          targeting {
            key
            value
          }
        }
      }
      # video {
      #   value
      #   inheritedValue
      # }
    }
  `,
};
