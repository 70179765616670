import React from 'react';
import { Link } from 'gatsby';

const SecondarySidebarSiteElements = ({ sites = [] }) => {
  const elements = sites
    .slice()
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    })
    .map(site => {
      return (
        <Link
          to={`/releases/${site.id}/`}
          key={site.id}
          className="btn btn-light btn-block btn-float m-0"
        >
          <img
            src={site.logo}
            className="img-fluid img-preview rounded"
            width="40"
            height="40"
            alt={site.name}
          />
          <span>{site.name}</span>
        </Link>
      );
    });

  return (
    <div className="row row-tile no-gutters">
      <div className="col-6">
        {elements.filter((el, i) => {
          return !(i % 2) && el;
        })}
      </div>
      <div className="col-6">
        {elements.filter((el, i) => {
          return i % 2 && el;
        })}
      </div>
    </div>
  );
};

const SecondarySidebar = ({ sites = [] }) => {
  return (
    <div className="sidebar sidebar-light sidebar-secondary sidebar-expand-md sidebar-fixed">
      <div className="sidebar-content">
        <div className="card">
          <div className="card-header bg-transparent header-elements-inline">
            <span className="text-uppercase font-size-sm font-weight-semibold">Brands</span>
          </div>

          <div className="card-body">
            <SecondarySidebarSiteElements sites={sites} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondarySidebar;
