import React from 'react';
import { Formik, FieldArray, Field } from 'formik';
import Button from '../Button';
import SaveButton from '../Form/SaveButton';
import FormRow, { FormGroup, FormLabel } from '../../components/Form/index';
import { FormikInput as Input } from '../../components/Form/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalTitle, ModalContainer, ModalActions, ModalHeading } from '../Modal';

/**
 * @type {React.Component<*>}
 */
const MappingModal = ({ name, value, onDismiss }) => {
  return (
    <Field>
      {({ form }) => (
        <Formik
          initialValues={value}
          onSubmit={(values, actions) => {
            form.setFieldValue(name, values);
            actions.setSubmitting(false);
            onDismiss();
          }}
        >
          {({ values, handleSubmit, isSubmitting }) => {
            return (
              <ModalContainer
                className="w-75"
                style={{ marginTop: '15vh' }}
                aria-label={`${value.label} Mapping`}
              >
                <ModalHeading inline>
                  <ModalTitle onDismiss={onDismiss}>
                    <span className="font-weight-semibold">{value.name}</span> - Mapping
                  </ModalTitle>

                  <ModalActions>
                    <Button type="button" theme="dark" variation="outline" className="btn-icon">
                      <FontAwesomeIcon
                        icon={['far', 'save']}
                        className="mr-1"
                        size="lg"
                        onClick={handleSubmit}
                      />{' '}
                      Save
                    </Button>
                    <Button
                      theme="dark"
                      variation="outline"
                      className="btn-icon"
                      onClick={onDismiss}
                    >
                      <FontAwesomeIcon icon={['fas', 'times']} className="mr-1" size="lg" /> Close
                    </Button>
                  </ModalActions>
                </ModalHeading>
                <div className="modal-body">
                  <FormRow label="Mapping Name" name="name" value={values.name} />
                  <FormRow label="Mapping Value" name="value" value={values.value} />

                  <FormGroup direction="column">
                    <FormLabel direction="column">Key Values</FormLabel>

                    <FieldArray name="targeting">
                      {({ push, remove }) => (
                        <>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>Key</th>
                                <th>Values</th>
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              {(values.targeting || []).map(({ key, value }, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <Input
                                        type="text"
                                        name={`targeting.${index}.key`}
                                        value={key}
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        type="text"
                                        name={`targeting.${index}.value`}
                                        value={value}
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-outline bg-danger text-danger-800 btn-icon"
                                        onClick={() => remove(index)}
                                      >
                                        <FontAwesomeIcon icon={['far', 'trash-alt']} size="lg" />
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <button
                            type="button"
                            className="btn bg-blue btn-labeled btn-labeled-right ml-auto mr-2 mt-2"
                            onClick={() =>
                              push({
                                key: '',
                                value: '',
                              })
                            }
                          >
                            <b>
                              <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                            </b>{' '}
                            Add new Key value
                          </button>
                        </>
                      )}
                    </FieldArray>
                  </FormGroup>

                  <div className="modal-footer">
                    <button type="button" className="btn btn-link" onClick={onDismiss}>
                      Close
                    </button>
                    <SaveButton onClick={handleSubmit} isLoading={isSubmitting} />
                  </div>
                </div>
              </ModalContainer>
            );
          }}
        </Formik>
      )}
    </Field>
  );
};

export default MappingModal;
