import React, { useEffect, useState } from 'react';
import { useMutation, useApolloClient, gql } from '@apollo/client';
import Noty from 'noty';
import { ModalContainer, ModalHeading, ModalActions, ModalTitle } from '../../../components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import SaveButton from '../../../components/Form/SaveButton';
import Button from '../../../components/Button';
import FormRow from '../../../components/Form';
import { FormikToggle as Toggle } from '../../../components/Form/Toggle';
import { FormikSelect as Select } from '../../../components/Form/Select';
import { asyncYupValidation } from '@utils/asyncYupValidation';
import { useDynamicQuery } from '@utils/useDynamicQuery';
import { convertUntouchedToNull } from '@utils/convertUntouchedToNull';
import { fillFieldsWithDefaultNull } from '@utils/index';
import { getYup } from '@utils/getYup';
import { isEnabled as isEnabledUtil } from '../utils';
import { klona } from 'klona/json';
import { displayNamesToValues, createSizeTag } from './utils';

async function buildSchema(genSchema) {
  const yup = await getYup();
  const schema = genSchema(yup);

  return schema.shape({
    enabled: yup.boolean(),
    sizes: yup.array().of(
      yup.object().shape({
        width: yup.number().nullable(true),
        height: yup.number().nullable(true),
        fluid: yup.boolean(),
        interstitial: yup.boolean(),
      })
    ),
  });
}

function BidderModalContent({
  // isVisible,
  client,
  siteId,
  bidder,
  onDismiss,
  showInheritanceBadge,
}) {
  const { called, loading, result, executeQuery } = useDynamicQuery(client);
  const [bidderFormSettings, setBidderFormSettings] = useState();

  useEffect(() => {
    import(
      /* webpackExports ["genSchema", "SettingsForm", "FRAGMENT_BIDDER_SETTINGS"] */
      /* webpackChunkName: "bidders/[request]" */
      `../forms/bidders/${bidder.id}`
    ).then(info => {
      setBidderFormSettings({
        genSchema: info.genSchema,
        SettingsForm: info.SettingsForm,
      });

      const GET_BIDDER_QUERY = gql`
        query getSiteSettings($id: ID!, $bidder: AvailableBidders!, $type: SettingsType!) {
          bidderSettings: bidderSettings(id: $id, bidder: $bidder, type: $type) {
            enabled {
              value
              inheritedValue
            }
            sizes {
              value {
                width
                height
                displayName
              }
              inheritedValue {
                width
                height
                displayName
              }
            }
            ...${info.FRAGMENT_BIDDER_SETTINGS.name}
          }

          availableFormats(id: $id, type: $type) {
            width
            height
            displayName
          }
        }
        ${info.FRAGMENT_BIDDER_SETTINGS.fragment}
      `;

      executeQuery(GET_BIDDER_QUERY, {
        variables: {
          id: siteId,
          bidder: bidder.id,
          type: showInheritanceBadge ? 'SITE' : 'ORGANISATION',
        },
        fetchPolicy: 'network-only',
      });
    });
  }, []);

  if (!called || loading || !result) {
    return <div></div>;
  }

  const bidderValue = {};
  Object.keys(result.data.bidderSettings).forEach(key => {
    bidderValue[key] = result.data.bidderSettings[key].value;
  });

  return (
    <BidderForm
      siteId={siteId}
      bidder={bidder}
      availableFormats={result.data.availableFormats}
      value={convertUntouchedToNull(bidderValue)}
      inheritedValue={result.data.bidderSettings}
      showInheritanceBadge={showInheritanceBadge}
      onDismiss={onDismiss}
      formComponent={bidderFormSettings.SettingsForm}
      validationSchema={bidderFormSettings.genSchema}
    />
  );
}

function BidderForm({
  siteId,
  bidder,
  availableFormats,
  value = {},
  inheritedValue,
  onDismiss,
  showInheritanceBadge,
  formComponent: FormComponent,
  validationSchema,
}) {
  const [saveSettings, { loading }] = useMutation(gql`
    mutation saveSettings($siteId: ID!, $type: SettingsType!, $settings: ${bidder.mutationId}Input!) {
      update${bidder.mutationId}Settings(id: $siteId, type: $type, input: $settings)
    }
  `);

  return (
    <Formik
      initialValues={value}
      validateOnChange={true}
      validateOnBlur={true}
      validate={async values => {
        const schema = await buildSchema(validationSchema);

        return asyncYupValidation(values, schema, {
          inheritedData: inheritedValue,
        });
      }}
      onSubmit={async values => {
        const schema = await buildSchema(validationSchema);
        const sanitizedValues = await schema.validate(klona(values), {
          stripUnknown: true,
        });

        const toast = new Noty({
          text: `Saving ${bidder.name} settings`,
          type: 'info',
        }).show();

        try {
          await saveSettings({
            variables: {
              siteId,
              settings: fillFieldsWithDefaultNull(sanitizedValues, schema),
              type: showInheritanceBadge ? 'SITE' : 'ORGANISATION',
            },
          });

          toast.setText(`${bidder.name} settings succesfully saved.`);
          toast.setType('success');

          onDismiss();
        } catch (err) {
          toast.setText(`Something went wrong saving ${bidder.name} settings.`);
          toast.setType('error');
        }
      }}
    >
      {({ values, isSubmitting, handleSubmit, errors, touched = {}, submitCount }) => {
        const isEnabled = isEnabledUtil(values, inheritedValue);

        const touchedErrors = {};
        for (const field in errors) {
          if (touched[field] || submitCount > 0) {
            touchedErrors[field] = errors[field];
          }
        }

        return (
          <>
            <ModalHeading inline style={{ borderBottom: '1px solid #ddd' }}>
              <ModalTitle onDismiss={onDismiss}>
                <span className="font-weight-semibold">{bidder.name}</span> - Bidder
              </ModalTitle>
              <ModalActions>
                <Button
                  theme="dark"
                  variation="outline"
                  className="btn-icon"
                  onClick={handleSubmit}
                >
                  <FontAwesomeIcon icon={['far', 'save']} className="mr-1" size="lg" /> Save
                </Button>
                <Button theme="dark" variation="outline" className="btn-icon" onClick={onDismiss}>
                  <FontAwesomeIcon icon={['fas', 'times']} className="mr-1" size="lg" /> Close
                </Button>
              </ModalActions>
            </ModalHeading>
            <div className="modal-body">
              <div className="row" style={{ borderBottom: '1px solid #ddd' }}>
                <div className="col-md-2 text-center">
                  <img className="mb-3" src={bidder.image} width="110" height="110" />
                </div>
                <div className="col-md-8">
                  <h6>Description</h6>
                  <p className="mb-4">{bidder.description}</p>
                  <FormRow
                    as={Toggle}
                    name="enabled"
                    label="Enabled"
                    value={true}
                    checked={values.enabled}
                    inheritedChecked={inheritedValue.enabled.inheritedValue}
                    showInheritanceBadge={showInheritanceBadge}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <h6>Settings</h6>
                  <div>
                    <FormRow
                      as={Select}
                      label="Sizes"
                      name="sizes"
                      availableData={availableFormats.map(format => format.displayName)}
                      createTag={createSizeTag}
                      value={values.sizes ? values.sizes.map(size => size.displayName) : null}
                      inheritedValue={(inheritedValue?.sizes?.inheritedValue || []).map(
                        size => size.displayName
                      )}
                      showInheritanceBadge={showInheritanceBadge}
                      onChange={val => {
                        return displayNamesToValues(val);
                      }}
                      padding="small"
                    />
                  </div>
                  <div>
                    {FormComponent && (
                      <FormComponent
                        isEnabled={isEnabled}
                        value={values}
                        inheritedValue={inheritedValue}
                        showInheritanceBadge={showInheritanceBadge}
                        errors={touchedErrors}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Button variation="link" onClick={onDismiss}>
                Close
              </Button>
              <SaveButton onClick={handleSubmit} isLoading={isSubmitting || loading} />
            </div>
          </>
        );
      }}
    </Formik>
  );
}

const BidderModal = ({ siteId, bidder, onDismiss, isOpen, showInheritanceBadge }) => {
  const client = useApolloClient();

  return (
    <ModalContainer isOpen={isOpen} aria-label={`${bidder.name} options`}>
      <BidderModalContent
        isVisible={isOpen}
        client={client}
        siteId={siteId}
        bidder={bidder}
        onDismiss={onDismiss}
        showInheritanceBadge={showInheritanceBadge}
      />
    </ModalContainer>
  );
};

export default BidderModal;
