import React from 'react';
import { Dialog } from '@reach/dialog';
import classNames from 'classnames';
import '@reach/dialog/styles.css';

/**
 * @type {React.FunctionComponent <{className?: string }>}
 */
export const ModalContainer = ({ children, ...props }) => {
  return (
    <Dialog {...props}>
      <div className="modal-content">{children}</div>
    </Dialog>
  );
};

/**
 * @type {React.FunctionComponent <{className?: string, inline?: boolean }>}
 */
export const ModalHeading = ({ className, inline, children, ...props }) => {
  const variationMap = {
    'header-elements-inline': inline,
  };

  return (
    <div className={classNames('page-header-content', variationMap, className)} {...props}>
      {children}
    </div>
  );
};

/**
 * @type {React.FunctionComponent <{as?: React.ComponentType<{className: string}>|string, onDismiss: Function }>}
 */
export const ModalTitle = ({ as: Component = 'h5', children, onDismiss }) => {
  return (
    <div className="page-title">
      <Component>
        <i className="icon-arrow-left52 mr-2" onClick={onDismiss} style={{ cursor: 'pointer' }} />
        {children}
      </Component>
    </div>
  );
};

/**
 * @type {React.FunctionComponent <{className?: string}>}
 */
export const ModalActions = ({ className, children, ...props }) => {
  return (
    <div className={classNames('header-elements', className)} {...props}>
      {children}
    </div>
  );
};

/**
 * @type {React.FunctionComponent <{title?: string|React.ReactNode, className?: string, actions?: React.ReactNode, titleVariation?: "light" }>}
 */
const Modal = ({ title, actions, children }) => {
  if (typeof title === 'string') {
    title = <ModalTitle>{title}</ModalTitle>;
  }

  return (
    <ModalContainer aria-label={title}>
      {title && (
        <ModalHeading>
          {title}
          {actions && <ModalActions>{actions}</ModalActions>}
        </ModalHeading>
      )}

      {children}
    </ModalContainer>
  );
};

export default Modal;
