import React from 'react';
import classNames from 'classnames';
import Button from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SaveButton = ({ onClick, isLoading, icon = 'save', children = 'Save Changes' }) => {
  return (
    <Button
      className="bg-green btn-labeled btn-labeled-right"
      onClick={onClick}
      disabled={isLoading}
    >
      <b>
        <FontAwesomeIcon
          icon={['fas', isLoading ? 'circle-notch' : icon]}
          className={classNames({ spinner: isLoading })}
        />
      </b>
      {children}
    </Button>
  );
};

export default SaveButton;
