import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ReleaseStats() {
  return (
    <>
      <div className="col">
        <div className="d-flex align-items-center">
          <div className="mr-3">
            <span className="btn bg-transparent border-primary text-primary rounded-round border-2 btn-icon">
              <FontAwesomeIcon icon={['fas', 'calendar-week']} />
            </span>
          </div>
          <div className="">
            <h5 className="font-weight-semibold mb-0">
              7{' '}
              <span className="text-success font-size-sm font-weight-normal">
                <i className="icon-arrow-up12"></i> (+2)
              </span>
            </h5>
            <span className="text-muted font-size-sm">last 7 days</span>
          </div>
        </div>
      </div>

      <div className="col">
        <div className="d-flex align-items-center">
          <div className="mr-3">
            <span className="btn bg-transparent border-primary text-primary rounded-round border-2 btn-icon">
              <FontAwesomeIcon icon={['fas', 'calendar-alt']} />
            </span>
          </div>
          <div className="">
            <h5 className="font-weight-semibold mb-0">
              32{' '}
              <span className="text-danger font-size-sm font-weight-normal">
                <i className="icon-arrow-down12"></i> (-5)
              </span>
            </h5>
            <span className="text-muted font-size-sm">last 30 days</span>
          </div>
        </div>
      </div>

      <div className="col">
        <div className="d-flex align-items-center">
          <div className="mr-3">
            <span className="btn bg-transparent border-primary text-primary rounded-round border-2 btn-icon">
              <FontAwesomeIcon icon={['fas', 'calendar']} />
            </span>
          </div>
          <div className="">
            <h5 className="font-weight-semibold mb-0">521</h5>
            <span className="text-muted font-size-sm">this year</span>
          </div>
        </div>
      </div>
    </>
  );
}
