import * as React from 'react';

const IS_ACTIVE_TIMEOUT = 20 * 1000;

export function useEffectWhenPageIsActive(callback, deps) {
  const isPageVisible = React.useRef(true);
  const mouseMoveTimerRef = React.useRef(null);
  const callbackCleanupRef = React.useRef(null);

  function run() {
    if (callbackCleanupRef.current) {
      cleanup();
    }

    callbackCleanupRef.current = callback();

    handleInteraction();
  }

  function cleanup() {
    if (callbackCleanupRef.current) {
      callbackCleanupRef.current();
      callbackCleanupRef.current = null;
    }
  }

  function handleInteraction() {
    if (mouseMoveTimerRef.current) {
      clearTimeout(mouseMoveTimerRef.current);
    }

    if (!callbackCleanupRef.current) {
      run();
    }

    mouseMoveTimerRef.current = setTimeout(() => {
      cleanup();
    }, IS_ACTIVE_TIMEOUT);
  }

  React.useEffect(() => {
    function handleVisibilityChange() {
      const currentIsActiveState = document.visibilityState === 'visible';

      if (currentIsActiveState !== isPageVisible.current) {
        isPageVisible.current = currentIsActiveState;

        if (currentIsActiveState) {
          run();
        } else {
          cleanup();
        }
      }
    }
    document.addEventListener('mousemove', handleInteraction, false);
    document.addEventListener('visibilitychange', handleVisibilityChange, false);

    // start running
    run();

    return () => {
      if (mouseMoveTimerRef.current) {
        clearTimeout(mouseMoveTimerRef.current);
        mouseMoveTimerRef.current = null;
      }

      cleanup();

      document.removeEventListener('mousemove', handleInteraction);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, deps);
}
