/** @jsx jsx */
import { useState, useEffect } from 'react';
import { css, jsx } from '@emotion/react';
import { Link } from 'gatsby';
import { gql } from '@apollo/client';
import { NavItem, SubNavigation } from './Navigation';
import { useAppContext } from '../../providers/App';

const initialCss = css`
  transform: translate3d(-20px, 0, 0);
  opacity: 0;
  transition: 0.3s ease opacity, 0.3s ease transform;
`;

const slideIn = css`
  transform: translate3d(0, 0, 0);
  opacity: 1;
`;

export default function Sidebar({ defaultSite }) {
  const { isNavigationCollapsed } = useAppContext();
  const [shouldAnimate, setAnimation] = useState(false);

  useEffect(() => {
    setAnimation(true);
  }, []);

  return (
    <div className="sidebar sidebar-dark sidebar-main sidebar-expand-md sidebar-fixed">
      <div className="sidebar-mobile-toggler text-center">
        <button type="button" className="sidebar-mobile-main-toggle">
          <i className="icon-arrow-left8" />
        </button>
        Navigation
        <button type="button" className="sidebar-mobile-expand">
          <i className="icon-screen-full" />
          <i className="icon-screen-normal" />
        </button>
      </div>

      <div className="sidebar-content">
        <div className="card card-sidebar-mobile">
          <ul className="nav nav-sidebar" data-nav-type="accordion">
            <li className="nav-item-header">
              <div className="text-uppercase font-size-xs line-height-xs">Main</div>{' '}
              <i className="icon-menu" title="Main" />
            </li>

            <NavItem
              to="/dashboard/"
              title="Dashboard"
              icon="home4"
              css={[
                initialCss,
                css`
                  transition-delay: 100ms;
                `,
                shouldAnimate && slideIn,
              ]}
            />

            <NavItem
              to={`/releases/${defaultSite.id}/`}
              title="Releases"
              icon="rocket"
              css={[
                initialCss,
                css`
                  transition-delay: 200ms;
                `,
                shouldAnimate && slideIn,
              ]}
            />

            <NavItem
              to="/settings"
              title="Settings"
              icon="cog3"
              css={[
                initialCss,
                css`
                  transition-delay: 300ms;
                `,
                shouldAnimate && slideIn,
              ]}
            >
              <SubNavigation data-submenu-title="Settings" animate={!isNavigationCollapsed}>
                <li className="nav-item">
                  <Link to="/settings/organisation" className="nav-link">
                    Organisation settings
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={`/settings/sites/${defaultSite.id}/`} className="nav-link">
                    Site settings
                  </Link>
                </li>
              </SubNavigation>
            </NavItem>

            {/* <NavItem
              to={`/history`}
              title="Change History"
              icon="history"
              currentActive={activeNav}
              setActive={setActive}
            /> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export const FRAGMENT_DEFAULT_SITE = {
  name: 'DefaultSiteFragment',
  fragment: gql`
    fragment DefaultSiteFragment on Site {
      id
    }
  `,
};
