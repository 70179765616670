import React from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { FormikInput } from './Input';
import MappingTable from './MappingTable';
import InheritanceBadge from './InheritanceBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/** @typedef {"row"|"col"} Direction */

/**
 * @type {React.SFC<{className?: string, direction?: Direction}>}
 */
export const FormGroup = ({ direction = 'row', className, children }) => {
  const classMap = {
    row: direction === 'row',
  };

  return <div className={classNames('form-group', classMap, className)}>{children}</div>;
};

/**
 * @type {React.SFC<{name: string, className?: string, direction?: "row"|"col"}>}
 */
export const FormLabel = ({ name, children, className, error, direction = 'row', ...props }) => {
  const classMap = {
    'col-form-label': direction === 'row',
    'text-danger': !!error,
  };

  return (
    <label htmlFor={name} className={classNames(classMap, className)} {...props}>
      {children}
    </label>
  );
};

/**
 * @type {React.SFC<{[key: string]: unknown, as: React.ComponentType|string}>}
 */
export const FormInput = ({ as: Component, ...props }) => {
  return <Component {...props} />;
};

export const Badge = ({ name, value, inherited }) => {
  // TODO refactor this
  const formik = useFormikContext();

  const showClearButton = value != null && inherited != null;

  return (
    <span className="ml-2 align-self-center">
      <InheritanceBadge value={value} inherited={inherited} />
      {showClearButton && (
        <button
          className="sl-2 btn btn-link btn-icon"
          data-tip="Clear value"
          data-place="right"
          type="button"
          onClick={() => {
            formik.setFieldValue(name, undefined);
            formik.setFieldTouched(name, false);
          }}
        >
          <FontAwesomeIcon icon="redo-alt" />
        </button>
      )}
    </span>
  );
};

/**
 * @type {React.SFC<{[key: string]: unknown, as: React.ComponentType|string, name: string, label: string, id?: string}>}
 */
const FormRow = ({
  name,
  label,
  id = name,
  as = FormikInput,
  direction = 'row',
  showInheritanceBadge = false,
  value,
  inheritedValue,
  error,
  padding = 'medium',
  children,
  ...props
}) => {
  const { checked, inheritedChecked, ...restProps } = props;

  // this might not be enough but for now it is.
  const isCheckboxType = Object.prototype.hasOwnProperty.call(props, 'checked');
  const isMappingTable = as === MappingTable || as instanceof MappingTable;
  const hasError = !!error;
  let badgeValue = isCheckboxType ? checked : value;
  let badgeInherited = isCheckboxType ? inheritedChecked : inheritedValue;

  if (isMappingTable && inheritedValue) {
    badgeInherited = Object.keys(inheritedValue).reduce((prev, key) => {
      const res = [prev].filter(Boolean);
      res.push(`${inheritedValue[key].name}=${inheritedValue[key].value}`);

      return res.join('&');
    }, '');
  }

  const formLabel = (
    <FormLabel name={id} error={error}>
      {label}
    </FormLabel>
  );

  if (children && typeof children === 'function') {
    return children({
      formLabel,
      badge: showInheritanceBadge ? (
        <Badge name={name} value={badgeValue} inherited={badgeInherited} />
      ) : null,
      hasError: !!error,
      error,
    });
  }

  const formInputProps = (
    <FormInput
      name={name}
      id={id}
      as={as}
      value={value}
      placeholder={inheritedValue}
      checked={!!(typeof checked === 'undefined' ? inheritedChecked : checked)}
      className={hasError ? 'border-danger' : null}
      {...restProps}
    />
  );

  if (direction === 'row') {
    return (
      <>
        <FormGroup direction={direction}>
          <div className={`col-lg-${padding === 'medium' ? '3' : '2'}`}>
            {formLabel}
            {showInheritanceBadge ? (
              <Badge name={name} value={badgeValue} inherited={badgeInherited} />
            ) : null}
          </div>
          <div className={`col-lg-${padding === 'medium' ? '7' : '8'}`}>
            <div className="form-group-feedback form-group-feedback-right">
              {formInputProps}
              {hasError && (
                <div className="form-control-feedback text-danger">
                  <FontAwesomeIcon icon="times-circle" />
                </div>
              )}
            </div>

            <div>{hasError ? <span className="form-text text-danger">{error}</span> : null}</div>
          </div>
        </FormGroup>
      </>
    );
  }

  return (
    <FormGroup direction={direction}>
      <div>
        {formLabel}
        {showInheritanceBadge ? (
          <Badge name={name} value={badgeValue} inherited={badgeInherited} />
        ) : null}
      </div>

      {formInputProps}

      <div>{hasError ? <span className="form-text text-danger">{error}</span> : null}</div>
    </FormGroup>
  );
};

export default FormRow;
