import React, { useState, useEffect, Fragment } from 'react';
import { navigate } from 'gatsby';
import { Router, Redirect } from '@reach/router';
import { ApolloProvider, gql } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { createApolloClient } from '../services/graphqlClient';
import Layout, { FRAGMENT_USER, FRAGMENT_DEFAULT_SITE } from '../layouts/console';
import OrganisationSettings from './console/organisationSettings';
import SiteSettings from './console/siteSettings';
import Releases from './console/releases';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { isAuthenticated } from '../services/auth';
import { AppProvider, CURRENT_ORG_KEY } from '../providers/App';
import { SidebarProvider } from '../providers/Sidebar';
import Dashboard from './console/dashboard';
import { LoadingScreen } from '../screens/Loading';

library.add(fas, far);

const PrivateRoute = ({ component: Component, authenticated, ...props }) => {
  if (authenticated === null) {
    return null;
  }

  if (authenticated === false) {
    navigate('/login/');
    return <div />;
  }

  return <Component {...props} />;
};

function RootRedirect() {
  return <Redirect to="/dashboard/" />;
}

function ApplicationRouter({ authenticated, data }) {
  return (
    <Router primary={false} component={Fragment}>
      <PrivateRoute path="/" component={Layout} authenticated={authenticated} {...data}>
        <RootRedirect path="/" />
        <Dashboard path="/dashboard/" />
        <Releases path="/releases/:siteId/" />
        <OrganisationSettings path="/settings/organisation/" />
        <SiteSettings path="/settings/sites/:siteId/" />
        {/* <History path="/history" /> */}
      </PrivateRoute>
    </Router>
  );
}

function AccountPage() {
  const [authenticated, setAuthenticated] = useState(null);
  const [layoutData, setLayoutData] = useState(null);

  if (typeof window === 'undefined') {
    return null;
  }

  const client = createApolloClient();

  useEffect(() => {
    isAuthenticated().then(setAuthenticated);
  }, []);

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    client
      .query({
        query: gql`
      query ApplicationQuery {
        me {
          ...${FRAGMENT_USER.name}
          defaultOrganisation {
            id
            name
            defaultSite {
              ...${FRAGMENT_DEFAULT_SITE.name}
            }
            sites {
              id
              name
              logo
            }
          }
        }
      }

      ${FRAGMENT_USER.fragment}
      ${FRAGMENT_DEFAULT_SITE.fragment}
    `,
      })
      .then(({ data }) => {
        setLayoutData(data);
      });
  }, [authenticated]);

  if (authenticated === null || (authenticated && !layoutData)) {
    return <LoadingScreen />;
  }

  let defaultOrganisation;
  try {
    const storedOrganisation = sessionStorage.getItem(CURRENT_ORG_KEY);
    if (storedOrganisation) {
      defaultOrganisation = JSON.parse(storedOrganisation);
    } else {
      defaultOrganisation = layoutData?.me?.defaultOrganisation;
    }
  } catch (err) {
    defaultOrganisation = layoutData?.me?.defaultOrganisation;
  }

  return (
    <ApolloProvider client={client}>
      <AppProvider defaultOrganisation={defaultOrganisation}>
        <SidebarProvider>
          <Helmet titleTemplate="%s - Adshim Portal" />
          <ApplicationRouter authenticated={authenticated} data={layoutData} />
        </SidebarProvider>
      </AppProvider>
    </ApolloProvider>
  );
}

export default React.memo(AccountPage, () => {
  return true;
});
