import React from 'react';
import { Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormatBlock, { FRAGMENT_FORMAT_SETTINGS, schema as formatSchema } from './FormatBlock';
import PrebidBlock, { FRAGMENT_PREBID_SETTINGS, schema as prebidSchema } from './PrebidBlock';
import PluginBlock, { FRAGMENT_PLUGIN_SETTINGS } from './PluginBlock';
import FloatingButton from '../../../components/Form/FloatingButton';
import { asyncYupValidation } from '../../../utils/asyncYupValidation';
import { gql } from '@apollo/client';
import { getYup } from '@utils/getYup';
import { fillFieldsWithDefaultNull } from '@utils/index.js';
import { klona } from 'klona/json';

function genSettingsSchema(yup) {
  return yup.object().shape({
    formats: formatSchema(yup),
    prebid: prebidSchema(yup),
  });
}

function touchErrorFields(errors, touched) {
  Object.keys(errors).forEach(key => {
    if (typeof errors[key] !== 'string') {
      if (typeof touched[key] !== 'object') {
        touched[key] = {};
      }

      touchErrorFields(errors[key], touched[key]);
    } else {
      touched[key] = true;
    }
  });
}

/**
 * @type {React.FunctionComponent}
 */
const SettingsForm = ({ data, showInheritanceBadge, onSubmit }) => {
  const formData = {
    formats: data.formats.value,
    prebid: {
      slots: data.prebid.slots.value,
    },
  };

  console.groupCollapsed('data');
  console.log({ data, formData });
  console.groupEnd();

  // const { formats, ...formData } = data;
  // if (formats) {
  //   formData.formats = formats.map(({ responsiveSizes, ...format }) => ({
  //     ...format,
  //     responsiveSizes: convertResponsiveSizesToArray(responsiveSizes),
  //   }));
  // }

  // if (inheritedData.formats) {
  //   inheritedData.formats = inheritedData.formats.map(({ responsiveSizes, ...format }) => ({
  //     ...format,
  //     responsiveSizes: convertResponsiveSizesToArray(responsiveSizes),
  //   }));
  // }

  return (
    <Formik
      enableReinitialize={false}
      initialValues={formData}
      validateOnChange={true}
      validateOnBlur={true}
      validate={async values => {
        const yup = await getYup();

        return asyncYupValidation(values, genSettingsSchema(yup), {
          inheritedData: data,
        });
      }}
      onSubmit={async values => {
        const yup = await getYup();
        const schema = genSettingsSchema(yup);
        const sanitizedValues = await schema.validate(klona(values), {
          stripUnknown: true,
        });

        await onSubmit(fillFieldsWithDefaultNull(sanitizedValues, schema));
      }}
    >
      {({ handleSubmit, values, errors, touched, submitCount }) => {
        if (submitCount > 0) {
          touchErrorFields(errors, touched);
        }
        console.log(errors);
        return (
          <>
            <form onSubmit={handleSubmit}>
              <FormatBlock
                value={values.formats}
                inheritedValue={data.formats.inheritedValue}
                showInheritanceBadge={showInheritanceBadge}
                name="formats"
                errors={errors.formats}
                touched={touched.formats}
              />
              <PrebidBlock
                value={values.prebid}
                inheritedValue={data.prebid}
                showInheritanceBadge={showInheritanceBadge}
                errors={errors.prebid}
                touched={touched.prebid}
                name="prebid"
              />
              <PluginBlock
                data={data.plugins}
                showInheritanceBadge={showInheritanceBadge}
                errors={errors.plugins}
                name="plugins"
              />

              <div>
                <button type="submit" className="btn bg-primary-400 btn-labeled btn-labeled-left">
                  <b>
                    <FontAwesomeIcon icon={['fas', 'save']} />
                  </b>{' '}
                  Save all settings
                </button>
              </div>
            </form>

            <FloatingButton menu={false} icon={['fas', 'save']} onClick={handleSubmit} />
          </>
        );
      }}
    </Formik>
  );
};

export const FRAGMENT_SETTINGS = {
  name: 'Settings',
  fragment: gql`
    fragment Settings on Settings {
      ...${FRAGMENT_FORMAT_SETTINGS.name}
      ...${FRAGMENT_PREBID_SETTINGS.name}
      ...${FRAGMENT_PLUGIN_SETTINGS.name}
    }
    ${FRAGMENT_FORMAT_SETTINGS.fragment}
    ${FRAGMENT_PLUGIN_SETTINGS.fragment}
    ${FRAGMENT_PREBID_SETTINGS.fragment}
  `,
};

export default SettingsForm;
