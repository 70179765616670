import React from 'react';
import FormRow from '../../../../components/Form';
import MappingTable from '../../../../components/Form/MappingTable';
import { settingsRequiredWhenEnabled } from '../../../../utils';
import { gql } from '@apollo/client';
import logo from '../../../../assets/images/bidders/ix.jpg';

export const id = 'indexexchange';
export const name = 'Index Exchange';
export const mutationId = 'IndexExchange';
export const description =
  "Grow advertising revenue and reach consumers on any screen with Index Exchange's global advertising marketplace and ad technology solutions.";
export const image = logo;

export const genSchema = Yup =>
  Yup.object()
    .default(undefined)
    .shape({
      siteId: settingsRequiredWhenEnabled(
        Yup.array()
          .of(
            Yup.object().shape({
              targeting: Yup.array().of(
                Yup.object().shape({
                  key: Yup.string(),
                  value: Yup.mixed(),
                })
              ),
              name: Yup.string(),
              value: Yup.mixed(),
            })
          )
          .label('Site ID')
      ),
    });

/**
 * @type {import('../../../../../types/settings').FormSection<{}>}
 */
export const SettingsForm = function IxSettingsForm({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) {
  return (
    <FormRow
      name="siteId"
      label="Site ID"
      value={value.siteId}
      inheritedValue={inheritedValue.siteId.inheritedValue ?? undefined}
      showInheritanceBadge={showInheritanceBadge}
      disabled={!isEnabled}
      error={errors.siteId}
      direction="column"
      as={MappingTable}
    />
  );
};

export const FRAGMENT_BIDDER_SETTINGS = {
  name: 'IndexExchangeBidderSettings',
  fragment: gql`
    fragment IndexExchangeBidderSettings on IndexExchangeBidder {
      siteId {
        value {
          name
          value
          targeting {
            key
            value
          }
        }
        inheritedValue {
          name
          value
          targeting {
            key
            value
          }
        }
      }
    }
  `,
};
