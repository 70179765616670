import React, { useState } from 'react';
import Noty from 'noty';
import { Helmet } from 'react-helmet';
import { navigate } from '@reach/router';
import { useAppContext } from '../../providers/App';
import PageTitle from '../../components/PageTitle';
import Breadcrumb from '../../components/Breadcrumb';
import Loader from '../../components/Loader';
import SettingsScreen, { FRAGMENT_SETTINGS } from '../../screens/settings';
import ReleaseDialog from '../../screens/settings/ReleaseDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery, useMutation, gql } from '@apollo/client';
import SettingsSidebar from '../../screens/settings/Sidebar';
import { settingsMetadata } from '../../data/settings';

Noty.overrideDefaults({
  theme: 'limitless',
  layout: 'topRight',
  type: 'alert',
  timeout: 2500,
});

const GET_SITE_SETTINGS = gql`
  query getSiteSettings($siteId: ID!) {
    site(siteId: $siteId) {
      ...SiteInfo
    }
    siteSettings(site: $siteId) {
      ...${FRAGMENT_SETTINGS.name}
    }
  }
  ${FRAGMENT_SETTINGS.fragment}
  ${SettingsScreen.fragments.site}
`;

const SAVE_SETTINGS = gql`
  mutation saveSettings($siteId: ID!, $settings: SettingsInput!) {
    updateSiteSettings(id: $siteId, input: $settings)
  }
`;

const CREATE_RELEASE = gql`
  mutation creatRelease($siteId: String!, $release: ReleaseInput!) {
    createRelease(site: $siteId, input: $release)
  }
`;

export default function SiteSettingsPage({ siteId }) {
  const { organisation } = useAppContext();
  const site = organisation.sites.find(site => site.id === siteId);
  const [showReleaseDialog, toggleReleaseDialog] = useState(false);

  const { loading, data, error } = useQuery(GET_SITE_SETTINGS, {
    variables: { siteId },
  });
  const [saveSettings] = useMutation(SAVE_SETTINGS);
  const [createRelease] = useMutation(CREATE_RELEASE);

  const onSubmit = async values => {
    await saveSettings({
      variables: {
        siteId,
        settings: { formats: values.formats, prebid: values.prebid, video: values.video },
      },
    });

    new Noty({
      text: 'Settings are saved',
      type: 'success',
    }).show();
  };

  const onCreateRelease = async ({ title, description = '' }) => {
    const toast = new Noty({
      text: 'Creating new release',
      type: 'success',
    }).show();

    try {
      await createRelease({
        variables: {
          siteId,
          release: { title, description },
        },
      });

      toggleReleaseDialog(false);
    } catch (err) {
      toast.close();
      new Noty({
        text: 'Something went wrong creating the release.',
        type: 'error',
      }).show();
    }
  };

  const menuItems = [];
  for (const [key, item] of Object.entries(settingsMetadata)) {
    menuItems.push({
      title: item.title,
      icon: item.icon,
      onClick: () => {
        navigate('#' + item.domId, { replace: true });
        document.getElementById(item.domId).scrollIntoView();
      },
      key,
    });
  }
  const settingsDropdown = [
    {
      title: 'Settings',
      icon: 'cogs',
      items: menuItems,
    },
  ];

  return (
    <>
      <SettingsSidebar sites={organisation.sites} />
      <div className="content-wrapper">
        <div className="page-header page-header-light">
          <Helmet title={`${site.name}' Settings`} />
          <PageTitle title="Site Settings">
            <button
              type="button"
              className="btn btn-primary btn-labeled btn-labeled-left"
              onClick={() => {
                toggleReleaseDialog(true);
              }}
            >
              <b>
                <FontAwesomeIcon icon={['fas', 'box']} />
              </b>
              Create release
            </button>
          </PageTitle>

          <Breadcrumb
            items={[
              ['Settings', '/settings'],
              [organisation.name, '/settings/organisation/'],
              [site.name],
            ]}
            menu={settingsDropdown}
          />
        </div>

        <div className="content">
          {loading && (
            <div style={{ height: '300px', position: 'relative' }}>
              <Loader />
            </div>
          )}

          {error && (
            <div>
              <ul>
                {error.graphQLErrors.map(({ message }, i) => (
                  <li key={i}>{message}</li>
                ))}
              </ul>
            </div>
          )}

          {!loading && !error && (
            <SettingsScreen
              key={siteId}
              entity={data.site}
              settings={data.siteSettings}
              onSubmit={onSubmit}
              showInheritanceBadge={true}
            />
          )}

          <ReleaseDialog
            variation="small"
            isOpen={showReleaseDialog}
            onSubmit={onCreateRelease}
            onDismiss={() => showReleaseDialog && toggleReleaseDialog(false)}
          />
        </div>
      </div>
    </>
  );
}
