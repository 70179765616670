import React, { useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import BlockCard from './BlockCard';
import Format from './Format';
import InheritanceBadge from '../../../components/Form/InheritanceBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql } from '@apollo/client';
import { schema as formatSchema } from './Format';
import { settingsMetadata } from '../../../data/settings';

const { adformats: metadata } = settingsMetadata;

export const schema = yup => {
  return yup.array().of(formatSchema(yup)).nullable(true);
};

const Badge = ({ name, value, inherited, reset }) => {
  // TODO refactor this
  const formik = useFormikContext();

  const showClearButton = Boolean(value && inherited != null);

  return (
    <span className="ml-2 align-self-center">
      <InheritanceBadge value={value} inherited={inherited} />
      {showClearButton && (
        <button
          className="sl-2 btn btn-link btn-icon"
          data-tip="Clear value"
          data-place="right"
          type="button"
          onClick={() => {
            formik.setFieldValue(name, null);
            formik.setFieldTouched(name, false);
            reset();
          }}
        >
          <FontAwesomeIcon icon="redo-alt" />
        </button>
      )}
    </span>
  );
};

/**
 * @type {React.FunctionComponent<import('../../../types/settings').Format>}
 */
const FormatBlock = ({ name, value = [], inheritedValue, showInheritanceBadge }) => {
  const [showInheritValue, toggleShowInheritValue] = useState(!value || !value.length);

  const title = (
    <>
      {metadata.title}
      {showInheritanceBadge && (
        <Badge
          name={name}
          value={value ?? undefined}
          inherited={inheritedValue ?? undefined}
          reset={() => toggleShowInheritValue(true)}
        />
      )}
    </>
  );

  return (
    <BlockCard id={metadata.domId} title={title} style={{ scrollMarginTop: '55px' }}>
      <FieldArray
        name={name}
        render={({ push, remove }) => {
          return (
            <>
              <div className="row flex-wrap">
                {value?.map((format, index) => {
                  return (
                    <Format
                      key={index}
                      name={`${name}.${index}`}
                      isOpen={format.code === ''}
                      value={format}
                      onRemove={() => remove(index)}
                    />
                  );
                })}
                {showInheritValue &&
                  inheritedValue &&
                  inheritedValue.map((format, index) => {
                    return <Format key={index} name={`${name}.${index}`} value={format} readOnly />;
                  })}
              </div>

              <button
                type="button"
                className="btn bg-blue btn-labeled btn-labeled-right ml-auto mr-2"
                onClick={() => {
                  toggleShowInheritValue(false);
                  push({
                    code: '',
                    fallbackSizes: [],
                    responsiveSizes: [],
                    outOfPage: false,
                    interstitial: false,
                  });
                }}
              >
                <b>
                  <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                </b>{' '}
                Add New Format
              </button>
            </>
          );
        }}
      />
    </BlockCard>
  );
};

export const FRAGMENT_FORMAT_SETTINGS = {
  name: 'FormatSettings',
  fragment: gql`
    fragment Format on SlotFormat {
      code
      fallbackSizes {
        width
        height
        fluid
        displayName
      }
      responsiveSizes {
        viewportOffset
        sizes {
          width
          height
          fluid
          displayName
        }
      }
      outOfPage: isOutOfPage
      interstitial: isInterstitial
    }

    fragment FormatSettings on Settings {
      formats {
        value {
          ...Format
        }
        inheritedValue {
          ...Format
        }
      }
    }
  `,
};

export default React.memo(FormatBlock);
