import React from 'react';
import classNames from 'classnames';

const InheritanceBadge = ({ value, inherited }) => {
  // Inheritance type library. Includes the color and text of the badge.
  let type = 'Site Only';

  if (inherited != null) {
    type = 'Inherited';
  }
  if (value != null && inherited != null) {
    type = 'Overridden';
  }

  const badgeClassnames = {
    'border-primary': type === 'Overridden',
    'text-primary-600': type === 'Overridden',
    'border-secondary': type === 'Inherited',
    'text-secondary-600': type === 'Inherited',
    'border-success': type === 'Site Only',
    'text-success-600': type === 'Site Only',
  };

  const dataTip = type === 'Site Only' ? null : `Inherited setting: <var>${inherited}</var>`;

  const badge = (
    <span
      className={classNames('badge', 'badge-flat', badgeClassnames)}
      data-tip={dataTip}
      style={type === 'Inherited' ? { cursor: 'help' } : null}
    >
      {type}
    </span>
  );

  return badge;
};

export default InheritanceBadge;
