import React from 'react';
import Card from '../../components/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { settingsMetadata } from '../../data/settings';
/**
 * @type {React.FC<{title: string, icon: string, iconClassname: string, goTo: string, anchor: string }>}
 */
const Shortcut = ({ title, icon, iconClassname, anchor, children, domId }) => {
  return (
    <Card>
      <div className="text-center">
        <a
          href={anchor}
          className={`btn bg-transparent rounded-round border-3 btn-icon mb-3 mt-1 p-3 border-${iconClassname} text-${iconClassname}`}
          style={{ width: '66px', height: '66px' }}
        >
          <FontAwesomeIcon icon={icon} size="2x" />
        </a>
        <h5 className="card-title">{title}</h5>

        {children}

        <button
          className={`btn bg-${iconClassname}`}
          onClick={() => {
            document.getElementById(domId)?.scrollIntoView();
          }}
        >
          Go to {title}
        </button>
      </div>
    </Card>
  );
};

/**
 * @type {React.FC<{}>}
 */
const Shortcuts = () => {
  const shortcuts = [];
  for (const [key, item] of Object.entries(settingsMetadata)) {
    shortcuts.push(
      <div key={key}>
        <Shortcut
          title={item.title}
          icon={item.icon}
          anchor={item?.anchor}
          domId={item.domId}
          iconClassname={`${item.color || 'primary'}-400`}
        >
          <div style={{ height: '80px' }}>
            <p
              className="mb-3"
              style={{
                WebkitLineClamp: 7,
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {item.description}
            </p>
          </div>
        </Shortcut>
      </div>
    );
  }
  return <div className="row shortcuts">{shortcuts}</div>;
};

/**
 * @type {React.SFC<{title: string, icon: string, goTo: string, anchor: string }>}
 */
const SecondaryShortcut = ({ title, icon, anchor, domId }) => {
  return (
    <li className="nav-item">
      <a
        href={anchor}
        className="nav-link"
        onClick={() => {
          document.getElementById(domId)?.scrollIntoView();
        }}
      >
        <FontAwesomeIcon icon={icon} className="sidebar__icon" /> {title}
      </a>
    </li>
  );
};

/**
 * @type {React.SFC<{}>}
 */
const SecondaryShortcuts = () => {
  const shortcuts = [];
  for (const [key, item] of Object.entries(settingsMetadata)) {
    shortcuts.push(
      <SecondaryShortcut
        key={key}
        title={item.title}
        icon={item.icon}
        anchor={item?.anchor}
        domId={item.domId}
      />
    );
  }
  return (
    <ul className="nav nav-sidebar" data-nav-type="accordion">
      {shortcuts}
    </ul>
  );
};

export { Shortcuts, SecondaryShortcuts };
