import React from 'react';
import FormRow from '../../../../components/Form';
import { settingsRequiredWhenEnabled } from '../../../../utils';
import { gql } from '@apollo/client';
import logo from '../../../../assets/images/plugins/cxense.png';

export const id = 'cxense';
export const name = 'Cxense';
export const description =
  'Cxense is an AI-powered Data Management and Intelligent Personalization platform that helps 200 leading publishers and marketers around the globe build personal, profitable relationships with their customers.';
export const image = logo;

export const genSchema = yup =>
  yup
    .object()
    .default(undefined)
    .shape({
      keyName: settingsRequiredWhenEnabled(yup.string().label('Key Name')),
      siteId: settingsRequiredWhenEnabled(yup.string().label('Site Id')),
    });

/**
 * @type {import('../../../../../types/settings').FormSection<{keyName: string, siteId: string }>}
 */
export const SettingsForm = ({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) => {
  return (
    <>
      <FormRow
        name="keyName"
        label="Key Name"
        value={value.keyName}
        inheritedValue={inheritedValue.keyName.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors['keyName']}
      />
      <FormRow
        name="siteId"
        label="Site Id"
        value={value.siteId}
        inheritedValue={inheritedValue.siteId.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors['siteId']}
      />
    </>
  );
};

export const FRAGMENT_PLUGIN_SETTINGS = {
  name: 'CxensePluginSettings',
  fragment: gql`
    fragment CxensePluginSettings on CxensePlugin {
      keyName {
        value
        inheritedValue
      }
      siteId {
        value
        inheritedValue
      }
    }
  `,
};
