import * as React from 'react';
import BlockCard from './BlockCard';
import PluginVendorCard from './PluginVendorCard';
import { gql } from '@apollo/client';
import { settingsMetadata } from '../../../data/settings';

const { plugin: metadata } = settingsMetadata;

const availablePlugins = require
  .context('../forms/plugins/', false, /\.js$/)
  .keys()
  .map(file => file.replace('./', '').replace('.js', ''));

/**
 * @typedef PluginSettings
 * @property {boolean} enabled
 * @property {Object} value
 */

/**
 * @type {import('../../../../types/settings').FormSection<PluginSettings>}
 */
const PluginBlock = ({ data = [], showInheritanceBadge }) => {
  const enabledPlugins = new Set();
  data.forEach(plugin => {
    if (plugin.enabled.computedValue) {
      enabledPlugins.add(plugin.name);
    }
  });

  return (
    <BlockCard title={metadata.title} id={metadata.domId} style={{ scrollMarginTop: '55px' }}>
      <div className="row plugin-tiles">
        {availablePlugins.map(pluginName => {
          return (
            <div key={pluginName}>
              <PluginVendorCard
                plugin={pluginName}
                isEnabled={enabledPlugins.has(pluginName)}
                showInheritanceBadge={showInheritanceBadge}
              />
            </div>
          );
        })}
      </div>
    </BlockCard>
  );
};

export default React.memo(PluginBlock);

export const FRAGMENT_PLUGIN_SETTINGS = {
  name: 'PluginSettings',
  fragment: gql`
    fragment PluginSettings on Settings {
      plugins {
        enabled {
          computedValue
        }
        name
      }
    }
  `,
};
