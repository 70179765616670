import React from 'react';
import Card from '../../components/Card';
import { Shortcuts } from './Shortcuts';
import SettingsForm from './SettingsForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql } from '@apollo/client';

export { FRAGMENT_SETTINGS } from './SettingsForm';

export default function SettingsScreen({ entity, settings, showInheritanceBadge, onSubmit }) {
  return (
    <>
      <Card>
        <div className="row">
          <div className="col d-flex justify-content-center">
            <div className="media mt-4">
              <div className="mr-3 mb-4">
                <img
                  src={entity.logo}
                  className="img-fluid img-preview rounded"
                  width="40"
                  height="40"
                  alt={entity.name}
                />
              </div>
              <div className="media-body">
                <h1 className="mb-0">{entity.name}</h1>
              </div>
            </div>
          </div>
        </div>

        <Shortcuts />
      </Card>

      <Card>
        <div className="media align-items-center align-items-md-start flex-column flex-md-row">
          <a
            href="#formats"
            className="btn bg-transparent border-success-400 text-success-400 rounded-round border-3 btn-icon btn-question mr-3 align-self-md-center"
          >
            <FontAwesomeIcon icon={['fas', 'question']} />
          </a>

          <div className="media-body text-center text-md-left">
            <h6 className="media-title font-weight-semibold">
              Can&apos;t find what you&apos;re looking for?
            </h6>
            <p>
              Send us a quick mail with your question and we&apos;ll look in to it as fast as
              possible!
            </p>
          </div>

          <button
            type="button"
            className="btn bg-warning-400 align-self-md-center ml-md-3 mt-3 mt-md-0"
          >
            <i className="icon-mail5 mr-2" /> <span>Submit a ticket</span>
          </button>
        </div>
      </Card>

      <div className="row">
        <div className="col">
          <SettingsForm
            data={settings}
            showInheritanceBadge={showInheritanceBadge}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </>
  );
}

SettingsScreen.fragments = {
  organisation: gql`
    fragment OrganisationInfo on Organisation {
      name
      logo
    }
  `,
  site: gql`
    fragment SiteInfo on Site {
      name
      logo
    }
  `,
};
