import React, { forwardRef } from 'react';
import classNames from 'classnames';

/**
 * @typedef {"light"} Variation
 */

/**
 * @type {React.SFC<{className?: string }>}
 */
export const CardContainer = ({ className, children, ...props }) => {
  return (
    <div className={classNames('card', className)} {...props}>
      {children}
    </div>
  );
};

/**
 * @type {React.SFC<{className?: string, inline?: boolean, variation?: Variation }>}
 */
export const CardHeading = ({ className, inline, variation, children, ...props }) => {
  const variationMap = {
    'bg-light': variation === 'light',
    'header-elements-inline': inline,
  };

  return (
    <div className={classNames('card-header', variationMap, className)} {...props}>
      {children}
    </div>
  );
};

/**
 * @type {React.SFC<{className?: string, as?: React.ComponentType<{className: string}>|string, variation?: Variation }>}
 */
export const CardTitle = ({ as: Component = 'h6', className, children, ...props }) => {
  return (
    <Component className={classNames('card-title', className)} {...props}>
      {children}
    </Component>
  );
};

/**
 * @type {React.SFC<{className?: string}>}
 */
export const CardActions = ({ className, children, ...props }) => {
  return (
    <div className={classNames('header-elements', className)} {...props}>
      {children}
    </div>
  );
};

/**
 * @type {React.SFC<{className?: string}>}
 */
// eslint-disable-next-line react/display-name
export const CardContent = forwardRef(({ className, children, ...props }, ref) => {
  return (
    <div ref={ref} className={classNames('card-body', className)} {...props}>
      {children}
    </div>
  );
});

/**
 * @type {React.SFC<{title?: string|React.ReactNode, className?: string, actions?: React.ReactNode, titleVariation?: "light" }>}
 */
const Card = ({ title, className, actions, titleVariation, children, ...props }) => {
  if (typeof title === 'string') {
    title = <CardTitle>{title}</CardTitle>;
  }

  return (
    <div className={classNames('card', className)} {...props}>
      {title && (
        <CardHeading variation={titleVariation} inline={true}>
          {title}
          {actions && <CardActions>{actions}</CardActions>}
        </CardHeading>
      )}

      <CardContent>{children}</CardContent>
    </div>
  );
};

export default Card;
