export function convertUntouchedToNull<T>(
  obj: T & { enabled: boolean | null },
  removeUndefined: boolean = false
): T | Partial<T> | undefined {
  let res = {};

  let hasValue = true;
  for (let key in obj) {
    if (obj[key] !== null && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      res[key] = convertUntouchedToNull(obj[key], true);
    } else {
      res[key] = obj[key] === null ? undefined : obj[key];
    }

    if (removeUndefined && typeof res[key] === 'undefined') {
      hasValue = false;
    }
  }

  return hasValue ? res : undefined;
}
