/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/react';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';
import classNames from 'classnames';
import { tween } from 'popmotion';
import posed from 'react-pose';
import withActiveState from './withActiveState';

const SubNavigationList = props => <ul {...props} />;
const AnimatedSubNavigationList = posed.ul({
  visible: {
    height: '100%',
    transition: tween,
    flip: true,
  },
  hidden: {
    height: '0px',
    transition: tween,
    flip: true,
  },
});

const NavItemLink = ({ children, isActive, onClick, ...props }) => {
  return (
    <Link
      className={classNames('nav-link', { active: !!isActive })}
      onClick={e => {
        e.preventDefault();

        onClick?.(e);
        navigate(props.to);
      }}
      {...props}
    >
      {children}
    </Link>
  );
};

const NavItemButton = ({ isActive, children, ...props }) => {
  return (
    <a href="#" className={classNames('nav-link', { active: !!isActive })} {...props}>
      {children}
    </a>
  );
};

const NavItemBase = ({ title, icon, children, isActive, className, ...props }) => {
  const isSubMenu = !!React.Children.count(children);
  const LinkComponent = isSubMenu ? NavItemButton : NavItemLink;
  const [isOpen, toggleOpen] = useState(!!isActive);
  let onClick = null;

  useEffect(() => {
    if (isSubMenu && !isActive) {
      toggleOpen(false);
    }
  }, [isActive]);

  let subnav = null;
  if (isSubMenu) {
    subnav = React.cloneElement(React.Children.only(children), { isOpen });
    onClick = function toggleSubNavigation(e) {
      e.preventDefault();
      toggleOpen(!isOpen);
    };
  }

  return (
    <li
      className={classNames('nav-item', className, {
        'nav-item-submenu': isSubMenu,
        'nav-item-open': isSubMenu ? isActive || isOpen : isActive,
      })}
    >
      <LinkComponent {...props} onClick={onClick}>
        {icon && <i className={`icon-${icon}`} />}
        <span>{title}</span>
      </LinkComponent>
      {subnav}
    </li>
  );
};

export const NavItem = withActiveState(NavItemBase);

export const SubNavigation = ({ children, animate, isOpen, ...props }) => {
  const Component = animate ? AnimatedSubNavigationList : SubNavigationList;

  return (
    <Component
      className={classNames('nav nav-group-sub')}
      css={
        animate && {
          overflow: 'hidden',
          display: 'block',
        }
      }
      pose={isOpen ? 'visible' : 'hidden'}
      {...props}
    >
      {children}
    </Component>
  );
};
