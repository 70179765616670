import React from 'react';
import FormRow from '../../../../components/Form';
import MappingTable from '../../../../components/Form/MappingTable';
import { gql } from '@apollo/client';
import logo from '../../../../assets/images/bidders/criteo.jpg';

export const id = 'criteo';
export const name = 'Criteo';
export const mutationId = name;
export const description =
  'Criteo maximizes revenue for web and app publishers by directly connecting your premium inventory to our premium demand.';
export const image = logo;

export const genSchema = yup =>
  yup
    .object()
    .default(undefined)
    .shape({
      networkId: yup.array().of(
        yup.object().shape({
          targeting: yup.array().of(
            yup.object().shape({
              key: yup.string(),
              value: yup.mixed(),
            })
          ),
          name: yup.string(),
          value: yup.mixed(),
        })
      ),
    });

/**
 * @type {import('../../../../../types/settings').FormSection<{}>}
 */
export const SettingsForm = function CriteoSettingsForm({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) {
  return (
    <>
      <FormRow
        name="networkId"
        label="Network ID"
        value={value.networkId}
        inheritedValue={inheritedValue.networkId.inheritedValue ?? undefined}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors.networkId}
        direction="column"
        as={MappingTable}
      />
    </>
  );
};

export const FRAGMENT_BIDDER_SETTINGS = {
  name: 'CriteoBidderSettings',
  fragment: gql`
    fragment CriteoBidderSettings on CriteoBidder {
      networkId {
        value {
          name
          value
          targeting {
            key
            value
          }
        }
        inheritedValue {
          name
          value
          targeting {
            key
            value
          }
        }
      }
    }
  `,
};
