import React from 'react';
import FormRow from '../../../../components/Form';
import { FormikToggle as Toggle } from '../../../../components/Form/Toggle';
import MappingTable from '../../../../components/Form/MappingTable';
import { gql } from '@apollo/client';
import logo from '../../../../assets/images/bidders/appnexus.jpg';

export const id = 'appnexus';
export const name = 'AppNexus';
export const mutationId = name;
export const description =
  'The AppNexus Publisher Suite enables publishers to increase their total yield through forecasting, SSP, ad serving, and audience extension. The AppNexus Programmable Platform (APP) is a customizable DSP that leverages advanced machine learning to deliver better outcomes for advertisers.';
export const image = logo;

export const genSchema = yup =>
  yup
    .object()
    .default(undefined)
    .shape({
      usePaymentRule: yup.boolean().label('Payment rule'),
      placementId: yup.array().of(
        yup.object().shape({
          targeting: yup.array().of(
            yup.object().shape({
              key: yup.string(),
              value: yup.mixed(),
            })
          ),
          name: yup.string(),
          value: yup.mixed(),
        })
      ),
    });

/**
 * @type {import('../../../../../types/settings').FormSection<{}>}
 */
export const SettingsForm = function AppNexusSettingsForm({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) {
  return (
    <>
      <FormRow
        name="placementId"
        label="Placement ID"
        value={value.placementId}
        inheritedValue={inheritedValue.placementId.inheritedValue ?? undefined}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors.placementId}
        direction="column"
        as={MappingTable}
      />
      <FormRow
        name="usePaymentRule"
        label="Payment rule"
        as={Toggle}
        value={true}
        checked={value.usePaymentRule}
        inheritedChecked={inheritedValue.usePaymentRule.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        error={errors.usePaymentRule}
        disabled={!isEnabled}
      />
    </>
  );
};

export const FRAGMENT_BIDDER_SETTINGS = {
  name: 'AppnexusBidderSettings',
  fragment: gql`
    fragment AppnexusBidderSettings on AppNexusBidder {
      placementId {
        value {
          name
          value
          targeting {
            key
            value
          }
        }
        inheritedValue {
          name
          value
          targeting {
            key
            value
          }
        }
      }
      usePaymentRule {
        value
        inheritedValue
      }
    }
  `,
};
