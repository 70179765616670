import React from 'react';
import FormRow from '../../../../components/Form';
import { FormikToggle as Toggle } from '../../../../components/Form/Toggle';
// import { FormikSelect as Select } from '../../../../components/Form/Select';
// import PriceGranularity from '../../../../components/Form/PriceGranularity';
import { settingsRequiredWhenEnabled } from '../../../../utils';
import { getCurrentValue } from '../../utils';
import { gql } from '@apollo/client';
import logo from '../../../../assets/images/plugins/prebid.png';

export const id = 'prebid';
export const name = 'Prebid';
export const description =
  'Prebid is: A set of free and open source software products that help publishers implement Header Bidding on their websites & apps.';
export const image = logo;

export const genSchema = yup =>
  yup
    .object()
    .default(undefined)
    .shape({
      timeout: settingsRequiredWhenEnabled(yup.number().allowEmptyString().label('Timeout')),
      prebidServer: yup.boolean().label('Prebid Server'),
      prebidServerAccountId: settingsRequiredWhenEnabled(
        yup.string().label('Prebid Server Account'),
        'prebidServer'
      ),
      // prebidServerBidders: settingsRequiredWhenEnabled(yup.number()),
      prebidServerEndpoint: settingsRequiredWhenEnabled(
        yup.string().label('Prebid Server Endpoint'),
        'prebidServer'
      ),
      // mediaTypePriceGranularity: settingsRequiredWhenEnabled(yup.number()),
    });

/**
 * @type {import('../../../../../types/settings').FormSection<{timeout: string, prebidServer: boolean, prebidServerAccountId: string, prebidServerEndpoint: string}>}
 */
export const SettingsForm = ({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) => {
  const isPrebidServerEnabled = !!getCurrentValue(value, inheritedValue, 'prebidServer');

  return (
    <>
      <FormRow
        name="timeout"
        label="Time Out"
        value={value.timeout}
        inheritedValue={inheritedValue.timeout.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors['timeout']}
      />
      <FormRow
        name="prebidServer"
        label="Prebid Server"
        as={Toggle}
        value={true}
        checked={!!value.prebidServer}
        inheritedValue={inheritedValue.prebidServer.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors['prebidServer']}
      />
      <FormRow
        name="prebidServerAccountId"
        label="Prebid Server Account Id"
        value={value.prebidServerAccountId}
        inheritedValue={inheritedValue.prebidServerAccountId.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled || !isPrebidServerEnabled}
        error={errors['prebidServerAccountId']}
      />
      {/* <FormRow
        name="prebidServerBidders"
        label="Prebid Server Bidders"
        as={Select}
        value={value.prebidServerBidders}
        inheritedValue={inheritedValue.prebidServerBidders}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled || !isPrebidServerEnabled}
        error={errors['prebidServerBidders']}
      /> */}
      <FormRow
        name="prebidServerEndpoint"
        label="Prebid Server Endpoint"
        value={value.prebidServerEndpoint}
        inheritedValue={inheritedValue.prebidServerEndpoint.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled || !isPrebidServerEnabled}
        error={errors['prebidServerEndpoint']}
      />
      {/* <PriceGranularity
        name="mediaTypePriceGranularity"
        label="Price Granularity"
        value={value.mediaTypePriceGranularity || {}}
        inheritedValue={inheritedValue.mediaTypePriceGranularity.inheritedValue || {}}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors['mediaTypePriceGranularity']}
      /> */}
    </>
  );
};

export const FRAGMENT_PLUGIN_SETTINGS = {
  name: 'PrebidPluginSettings',
  fragment: gql`
    fragment PrebidPluginSettings on PrebidPlugin {
      timeout {
        value
        inheritedValue
      }
      prebidServer {
        value
        inheritedValue
      }
      prebidServerAccountId {
        value
        inheritedValue
      }
      prebidServerBidders {
        value
        inheritedValue
      }
      prebidServerEndpoint {
        value
        inheritedValue
      }
    }
  `,
};
