import React from 'react';
import { gql } from '@apollo/client';
import FormRow from '../../../../components/Form';
import MappingTable from '../../../../components/Form/MappingTable';
import { settingsRequiredWhenEnabled } from '../../../../utils';
import logo from '../../../../assets/images/bidders/improve-digital.jpg';

export const id = 'improvedigital';
export const name = 'Improve Digital';
export const mutationId = 'ImproveDigital';
export const description =
  'Improve Digital is the All-in-One Advertising Platform for Publishers, Content Providers and Broadcasters.';
export const image = logo;

export const genSchema = yup =>
  yup
    .object()
    .default(undefined)
    .shape({
      placementId: settingsRequiredWhenEnabled(
        yup.array().of(
          yup.object().shape({
            targeting: yup.array().of(
              yup.object().shape({
                key: yup.string(),
                value: yup.mixed(),
              })
            ),
            name: yup.string(),
            value: yup.mixed(),
          })
        )
      ),
      publisherId: yup.number().allowEmptyString().label('Publisher ID'),
      placementKey: yup.string().label('Placement Key'),
    });

/**
 * @type {import('../../../../../types/settings').FormSection<{}>}
 */
export const SettingsForm = function ImproveDigitalSettingsForm({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) {
  return (
    <>
      <FormRow
        name="placementId"
        label="Placement ID"
        value={value.placementId}
        inheritedValue={inheritedValue.placementId.inheritedValue ?? undefined}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors.placementId}
        direction="column"
        as={MappingTable}
      />
      <FormRow
        name="publisherId"
        label="Publisher ID"
        value={value.publisherId}
        inheritedValue={inheritedValue.publisherId.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        error={errors.publisherId}
        disabled={!isEnabled}
      />
      <FormRow
        name="placementKey"
        label="Placement Key"
        value={value.placementKey}
        inheritedValue={inheritedValue.placementKey.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        error={errors.placementKey}
        disabled={!isEnabled}
      />
    </>
  );
};

export const FRAGMENT_BIDDER_SETTINGS = {
  name: 'ImproveDigitalBidderSettings',
  fragment: gql`
    fragment ImproveDigitalBidderSettings on ImproveDigitalBidder {
      placementId {
        value {
          name
          value
          targeting {
            key
            value
          }
        }
        inheritedValue {
          name
          value
          targeting {
            key
            value
          }
        }
      }
      publisherId {
        value
        inheritedValue
      }
      placementKey {
        value
        inheritedValue
      }
    }
  `,
};
