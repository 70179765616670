import React from 'react';
import FormRow from '../../../../components/Form';
import { settingsRequiredWhenEnabled } from '../../../../utils';
import { isEnabled as isEnabledUtil } from '../../utils';
import { gql } from '@apollo/client';
import logo from '../../../../assets/images/plugins/krux.png';

export const id = 'krux';
export const name = 'Krux';
export const description =
  'Krux helps marketers, publishers and agencies drive revenue by delivering smarter content, commerce and marketing experiences.';
export const image = logo;

export const genSchema = yup =>
  yup
    .object()
    .default(undefined)
    .shape({
      keyName: settingsRequiredWhenEnabled(yup.string().label('Key Name')),
    });

/**
 * @type {import('../../../../../types/settings').FormSection<{keyName: string}>}
 */
// eslint-disable-next-line
export const SettingsForm = ({
  value = {},
  inheritedValue = {},
  errors = {},
  showInheritanceBadge,
}) => {
  const isEnabled = isEnabledUtil(value, inheritedValue);

  return (
    <>
      <FormRow
        name="keyName"
        label="Key Name"
        value={value.keyName}
        inheritedValue={inheritedValue.keyName.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors['keyName']}
      />
    </>
  );
};

export const FRAGMENT_PLUGIN_SETTINGS = {
  name: 'KruxPluginSettings',
  fragment: gql`
    fragment KruxPluginSettings on KruxPlugin {
      keyName {
        value
        inheritedValue
      }
    }
  `,
};
