import React from 'react';
import { navigate } from 'gatsby';
import ReleaseStats from './content/ReleaseStats';
import ReleaseTable from './content/ReleaseTable';
import Card from '../../components/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ReleaseScreen({ site, ...props }) {
  return (
    <>
      <div className="d-flex justify-content-end p-2 rounded" />
      <div className="row">
        <div className="col">
          {/* <div className="card">
            <div className="card-body"> */}
          <div className="row">
            <div className="col d-flex justify-content-center">
              <div className="media">
                <div className="mr-3 mb-4">
                  <img
                    src={site.logo}
                    className="img-fluid img-preview rounded"
                    width="40"
                    height="40"
                    alt={site.name}
                  />
                </div>
                <div className="media-body">
                  <h1 className="mb-0">{site.name}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Card>
        <div className="media align-items-center align-items-md-start flex-column flex-md-row">
          <span
            style={{ cursor: 'default' }}
            className="btn bg-transparent border-success-400 text-success-400 rounded-round border-3 btn-icon btn-question mr-3 align-self-md-center"
          >
            <FontAwesomeIcon icon={['fas', 'question']} />
          </span>

          <div className="media-body text-center text-md-left">
            <h6 className="media-title font-weight-semibold">Want to create a new release?</h6>
            <p>Go to the settings page and create a new release now.</p>
          </div>

          <button
            type="button"
            className="btn bg-primary-400 align-self-md-center ml-md-3 mt-3 mt-md-0"
            onClick={() => navigate(`/settings/sites/${site.id}/`)}
          >
            <i className="icon-rocket mr-2" /> <span>Go to settings</span>
          </button>
        </div>
      </Card>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header mt-2">
              <div className="row text-center">
                <div className="col d-flex">
                  <h5 className="align-self-center">Releases</h5>
                </div>{' '}
                <ReleaseStats />
              </div>
            </div>

            <ReleaseTable key={site.id} {...props} />
          </div>
        </div>
      </div>
    </>
  );
}
