import React from 'react';
import FormRow from '../../../../components/Form';
import MappingTable from '../../../../components/Form/MappingTable';
import { gql } from '@apollo/client';
import logo from '../../../../assets/images/userIds/id5.jpg';

export const id = 'id5';
export const name = 'Id5';
export const mutationId = 'ID5';
export const description =
  'Universal IDs are shared, persistent identifiers that connect users across the digital marketing ecosystem.';
export const image = logo;

export const genSchema = yup =>
  yup
    .object()
    .default(undefined)
    .shape({
      partnerId: yup.array().of(
        yup.object().shape({
          targeting: yup.array().of(
            yup.object().shape({
              key: yup.string(),
              value: yup.mixed(),
            })
          ),
          name: yup.string(),
          value: yup.mixed(),
        })
      ),
    });

/**
 * @type {import('../../../../../types/settings').FormSection<{}>}
 */
export const SettingsForm = function CriteoSettingsForm({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) {
  return (
    <>
      <FormRow
        name="partnerId"
        label="Partner ID"
        value={value.partnerId}
        inheritedValue={inheritedValue.partnerId.inheritedValue ?? undefined}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors.partnerId}
        direction="column"
        as={MappingTable}
      />
    </>
  );
};

export const FRAGMENT_USERID_SETTINGS = {
  name: 'Id5UserIdSettings',
  fragment: gql`
    fragment Id5UserIdSettings on ID5UserId {
      partnerId {
        value {
          name
          value
          targeting {
            key
            value
          }
        }
        inheritedValue {
          name
          value
          targeting {
            key
            value
          }
        }
      }
    }
  `,
};
