import React from 'react';
import * as yup from 'yup';
import { ModalContainer, ModalHeading, ModalTitle } from '../../components/Modal';
import { Formik } from 'formik';
import FormRow from '../../components/Form';
import SaveButton from '../../components/Form/SaveButton';
import Button from '../../components/Button';
import { FormikTextarea as Textarea } from '../../components/Form/Textarea';

const schema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string(),
});

const ReleaseDialog = ({ onSubmit, onDismiss, isOpen }) => {
  return (
    <ModalContainer isOpen={isOpen} aria-label="Create release">
      <Formik
        validationSchema={schema}
        initialValues={{}}
        onSubmit={async values => void (await onSubmit(values))}
      >
        {({ values, handleSubmit, isSubmitting, errors }) => {
          return (
            <>
              <ModalHeading inline style={{ borderBottom: '1px solid #ddd' }}>
                <ModalTitle onDismiss={onDismiss}>Create release</ModalTitle>
              </ModalHeading>

              <div className="modal-body">
                <FormRow
                  direction="row"
                  label="Release name"
                  name="title"
                  value={values.title}
                  errors={errors.title || {}}
                />
                <FormRow
                  direction="row"
                  label="Description"
                  name="description"
                  as={Textarea}
                  value={values.description}
                  errors={errors.description || {}}
                />
              </div>

              <div className="modal-footer">
                <Button variation="link" onClick={onDismiss}>
                  Close
                </Button>
                <SaveButton onClick={handleSubmit} icon="box" isLoading={isSubmitting}>
                  Create Release
                </SaveButton>
              </div>
            </>
          );
        }}
      </Formik>
    </ModalContainer>
  );
};

export default ReleaseDialog;
