import React, { createContext, useState, useContext } from 'react';
import { navigate } from '@reach/router';
import { Helmet } from 'react-helmet';

export const CURRENT_ORG_KEY = 'ADSHIM_CURRENT_ORG_v2';

/**
 * @typedef App
 * @property {boolean} isNavigationCollapsed
 * @property {Function} collapseNavigation
 * @property {{id: string, name: string, defauiltSite: { id: string }}} organisation
 * @property {Function} setOrganisation
 */

/** @type {React.Context<App} */
const AppContext = createContext({
  isNavigationCollapsed: false,
  organisation: null,
});

export const AppProvider = ({ defaultOrganisation, children }) => {
  const [isCollapsed, collapseNavigation] = useState(false);
  const [currentOrganisation, setOrganisation] = useState(defaultOrganisation);

  const contextValue = {
    isNavigationCollapsed: isCollapsed,
    collapseNavigation,
    organisation: currentOrganisation,
    setOrganisation: organisation => {
      // TODO: stay on the same page
      navigate('/dashboard/').then(() => {
        setOrganisation(organisation);
      });

      try {
        sessionStorage.setItem(CURRENT_ORG_KEY, JSON.stringify(organisation));
      } catch (err) {
        // no catch
      }
    },
  };

  return (
    <AppContext.Provider value={contextValue}>
      <Helmet
        bodyAttributes={{
          class: `navbar-top ${isCollapsed ? 'sidebar-xs' : null}`,
        }}
      />

      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
