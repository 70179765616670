import React from 'react';
import classNames from 'classnames';

/**
 * @typedef ButtonProps
 * @property {string} className
 * @property {"dark"|undefined} theme
 * @property {"outline"|"link"|undefined} variation
 */

/** @type {React.FC<ButtonProps & React.ButtonHTMLAttributes>} */
function Button({ className, children, theme, variation, type = 'button', ...props }, ref) {
  const classMap = {
    'btn-outline': variation === 'outline',
    'btn-link': variation === 'link',
    'bg-dark': theme === 'dark',
    'text-dark-800': theme === 'dark',
  };

  return (
    <button type={type} ref={ref} className={classNames('btn', className, classMap)} {...props}>
      {children}
    </button>
  );
}

const ForwardedButton = React.forwardRef(Button);

export default ForwardedButton;
