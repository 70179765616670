import React from 'react';
import FormRow from '../../../../components/Form';
import { settingsRequiredWhenEnabled } from '../../../../utils';
import { gql } from '@apollo/client';
import logo from '../../../../assets/images/plugins/adomik.png';

export const id = 'adomik';
export const name = 'Adomik';
export const description =
  'Adomik resolves the complexity of managing digital advertising for all departments in publisher organizations, allowing them to improve their business performance and grow their revenue seamlessly.';
export const image = logo;

export const genSchema = yup =>
  yup.object().shape({
    id: settingsRequiredWhenEnabled(yup.string().label('ID')),
    url: settingsRequiredWhenEnabled(yup.string().label('Url')),
  });

/**
 * @type {import('../../../../../types/settings').FormSection<{ id: string, url: string }>}
 */
export const SettingsForm = ({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) => {
  return (
    <>
      <FormRow
        name="id"
        label="ID"
        value={value.id}
        inheritedValue={inheritedValue.id.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors['id']}
      />
      <FormRow
        name="url"
        label="Url"
        value={value.url}
        inheritedValue={inheritedValue.url.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors['url']}
      />
    </>
  );
};

export const FRAGMENT_PLUGIN_SETTINGS = {
  name: 'AdomikPluginSettings',
  fragment: gql`
    fragment AdomikPluginSettings on AdomikPlugin {
      id {
        value
        inheritedValue
      }
      url {
        value
        inheritedValue
      }
    }
  `,
};
