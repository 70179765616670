import React, { useRef } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { connect } from 'formik';

const getJQuery = async () => {
  const $ = await import('jquery').then(({ default: $ }) => $);

  await import('../../assets/limitless/global_assets/js/plugins/forms/selects/select2.min.js');

  return $;
};

/**
 * @type {React.FunctionComponent<{name: string, placeholder: string, defaultValue: string[]|[string, string][], disabled: boolean, value: string[]|[string, string][], onChange: () => void?}>}
 */
const Select = ({
  name,
  availableData,
  value,
  defaultValue = [],
  onChange,
  placeholder,
  disabled,
  multiple = true,
  ...options
}) => {
  const selectRef = useRef(null);

  const selected = value;
  // set data depending on value
  let data = availableData;
  if (!data && value) {
    data = Array.from(new Set((multiple ? value : [value]).concat(defaultValue)));
  }

  useDeepCompareEffect(() => {
    let $;
    (async () => {
      $ = await getJQuery();

      $(selectRef.current).select2({
        ...options,
        multiple,
        data,
        tags: options.createTag && !availableData ? true : false,
      });

      $(selectRef.current).val(selected).trigger('change');

      if (onChange) {
        $(selectRef.current).on('change.select2', () => {
          const selectedValues = $(selectRef.current)
            .select2('data')
            .map(value => value.id);

          if (multiple) {
            onChange(selectedValues);
          } else {
            onChange(selectedValues.length ? selectedValues[0] : null);
          }
        });
      }
    })();

    return () => {
      if (selectRef.current && $) {
        if ($(selectRef.current).data('select2')) {
          $(selectRef.current).select2('destroy');
        }
      }
    };
  }, [multiple, options]);

  return (
    <select
      id={name}
      name={name}
      multiple={multiple}
      disabled={disabled}
      ref={selectRef}
      data-placeholder={placeholder}
      className="form-control"
    ></select>
  );
};

export const FormikSelect = connect(({ formik, name, onChange, ...props }) => {
  const onChangeHandler = value => formik.setFieldValue(name, onChange ? onChange(value) : value);

  return <Select name={name} onChange={onChangeHandler} {...props} />;
});

export default Select;
