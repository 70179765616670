import React, { useState } from 'react';
import Noty from 'noty';
import { Helmet } from 'react-helmet';
import { navigate } from '@reach/router';
import { useAppContext } from '../../providers/App';
import PageTitle from '../../components/PageTitle';
import Breadcrumb from '../../components/Breadcrumb';
import Loader from '../../components/Loader';
import SettingsScreen, { FRAGMENT_SETTINGS } from '../../screens/settings';
import ReleaseDialog from '../../screens/settings/ReleaseDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery, useMutation, gql } from '@apollo/client';
import { settingsMetadata } from '../../data/settings';

const GET_ORGANISATION_SETTINGS = gql`
  query getOrganisationSettings($organisationId: ID!) {
    organisationSettings(organisation: $organisationId) {
      ...${FRAGMENT_SETTINGS.name}
    }
    organisation(id: $organisationId) {
      ...OrganisationInfo
    }
  }

  ${FRAGMENT_SETTINGS.fragment}
  ${SettingsScreen.fragments.organisation}
`;

const SAVE_SETTINGS = gql`
  mutation saveSettings($organisationId: ID!, $settings: SettingsInput!) {
    updateOrganisationSettings(id: $organisationId, input: $settings)
  }
`;

const CREATE_RELEASE = gql`
  mutation createReleasesFromOrganisation($orgId: String!, $release: ReleaseInput!) {
    createReleasesFromOrganisation(organisation: $orgId, input: $release)
  }
`;

Noty.overrideDefaults({
  theme: 'limitless',
  layout: 'topRight',
  type: 'alert',
  timeout: 2500,
});

export default function Index() {
  const { organisation } = useAppContext();
  const [showReleaseDialog, toggleReleaseDialog] = useState(false);
  const { loading, data } = useQuery(GET_ORGANISATION_SETTINGS, {
    variables: { organisationId: organisation.id },
  });
  const [saveSettings] = useMutation(SAVE_SETTINGS);
  const [createRelease] = useMutation(CREATE_RELEASE);

  const onSubmit = async values => {
    await saveSettings({
      variables: {
        organisationId: organisation.id,
        settings: { formats: values.formats, prebid: values.prebid, video: values.video },
      },
    });

    new Noty({
      text: 'Settings are saved',
      type: 'success',
    }).show();
  };
  const onCreateRelease = async ({ title, description = '' }) => {
    const toast = new Noty({
      text: 'Creating new releases',
      type: 'success',
    }).show();

    try {
      await createRelease({
        variables: {
          orgId: organisation.id,
          release: { title, description },
        },
      });

      toggleReleaseDialog(false);
    } catch (err) {
      toast.close();
      new Noty({
        text: 'Something went wrong creating the release.',
        type: 'error',
      }).show();
    }
  };

  const menuItems = [];
  for (const [key, item] of Object.entries(settingsMetadata)) {
    menuItems.push({
      title: item.title,
      icon: item.icon,
      onClick: () => {
        navigate('#' + item.domId, { replace: true });
        document.getElementById(item.domId).scrollIntoView();
      },
      key,
    });
  }
  const settingsDropdown = [
    {
      title: 'Settings',
      icon: 'cogs',
      items: menuItems,
    },
  ];

  return (
    <>
      <div className="content-wrapper">
        <div className="page-header page-header-light">
          <Helmet title={`${organisation.name}' Settings`} />
          <PageTitle title="Organisation Settings">
            <button
              type="button"
              className="btn btn-primary btn-labeled btn-labeled-left"
              onClick={() => {
                toggleReleaseDialog(true);
              }}
            >
              <b>
                <FontAwesomeIcon icon={['fas', 'box']} />
              </b>
              Create releases
            </button>
          </PageTitle>

          <Breadcrumb
            items={[
              ['Settings', '/settings'],
              ['Organisation', ''],
            ]}
            menu={settingsDropdown}
          />
        </div>

        <div className="content">
          {loading && (
            <div style={{ height: '300px', position: 'relative' }}>
              <Loader />
            </div>
          )}

          {!loading && (
            <SettingsScreen
              settings={data.organisationSettings}
              entity={data.organisation}
              onSubmit={onSubmit}
            />
          )}

          <ReleaseDialog
            variation="small"
            isOpen={showReleaseDialog}
            onSubmit={onCreateRelease}
            onDismiss={() => showReleaseDialog && toggleReleaseDialog(false)}
          />
        </div>
      </div>
    </>
  );
}
