import React from 'react';
import { connect } from 'formik';
import Switch from 'react-switchery-component';
import 'react-switchery-component/react-switchery-component.css';

const calculateFalsyValue = v => {
  if (typeof v === 'boolean') {
    return !v;
  }
  if (v == 1) {
    return typeof v == 'string' ? '0' : 0;
  }
  if (v == 0) {
    return typeof v == 'string' ? '1' : 1;
  }

  return null;
};

const Toggle = ({ name, id = name, checked, value, onChange, disabled, disabledTooltip }) => {
  return (
    <span data-tip={disabled ? disabledTooltip : undefined}>
      <Switch
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={e => onChange && onChange(e.target.checked ? value : calculateFalsyValue(value))}
        disabled={disabled}
      />
    </span>
  );
};

export const FormikToggle = connect(({ formik, name, id = name, onChange, ...props }) => {
  const onChangeHandler = value => formik.setFieldValue(name, onChange ? onChange(value) : value);

  return <Toggle id={id} name={name} onChange={onChangeHandler} {...props} />;
});

export default Toggle;
