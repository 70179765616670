import React from 'react';
import ReleaseRow from './ReleaseRow';

export default function ReleaseTable({ releases, ...props }) {
  return (
    <div className="table-responsive">
      <table className="table text-nowrap">
        <thead>
          <tr>
            <th style={{ width: '100px' }}>#</th>
            <th style={{ width: '100px' }}>Created</th>
            <th style={{ width: '200px' }}>User</th>
            <th style={{ width: '100px' }} className="text-center">
              Type
            </th>
            <th>Description</th>
            <th style={{ width: '150px' }}>Deployed</th>
            <th style={{ width: '400px' }}>Action</th>
            <th className="text-center" style={{ width: '20px' }}>
              <i className="icon-arrow-down12"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {releases.map(release => {
            return <ReleaseRow key={release.id} release={release} {...props} />;
          })}
        </tbody>
      </table>
    </div>
  );
}
