import React, { useState, useEffect } from 'react';
import posed from 'react-pose';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormRow from '../../../components/Form';
import { FormikToggle as Toggle } from '../../../components/Form/Toggle';
import {
  CardContainer,
  CardHeading,
  CardActions,
  CardTitle,
  CardContent,
} from '../../../components/Card';
import { FormikSelect as Select } from '../../../components/Form/Select';

const CardAccordianContent = posed.div({
  opened: {
    height: '100%',
    opacity: 1,
  },
  closed: {
    height: '0px',
    opacity: 0,
  },
});

export const schema = yup =>
  yup.object().shape({
    id: yup.string().required().label('Ad identifier'),
    type: yup.string().required().label('Inventory type'),
    bidders: yup.array().of(yup.string()).nullable(true),
  });

const ActionButton = ({ icon, ...props }) => (
  <button type="button" className="btn bg-transparent  btn-icon" {...props}>
    <FontAwesomeIcon icon={['fas', icon]} className="mr-1" size="lg" />
  </button>
);

const createLabel = (bidder, isEnabled) => {
  return (
    <>
      {bidder.name}
      {!isEnabled && (
        <span>
          <FontAwesomeIcon
            icon={['fas', 'info-circle']}
            data-tip={`First enable "${bidder.name}" in Prebid bidder settings`}
            className="ml-2 text-primary"
            size="lg"
          />
          <ReactTooltip place="right" type="dark" effect="float" />
        </span>
      )}
    </>
  );
};

const PrebidSlot = ({
  onRemove,
  availableBidders = [],
  enabledBidders = new Set(),
  isOpen: defaultOpen,
  readOnly,
  name,
  value,
  error,
}) => {
  const [isOpen, toggleOpen] = useState(defaultOpen);
  const [biddersInfo, setBiddersInfo] = useState([]);

  useEffect(() => {
    Promise.all(
      availableBidders.map(bidder =>
        import(`../forms/bidders/${bidder}`).then(bidderInfo => ({
          key: bidder,
          ...bidderInfo,
        }))
      )
    ).then(setBiddersInfo);
  }, [availableBidders]);

  return (
    <CardContainer className="w-100">
      <CardHeading inline={true}>
        <CardTitle as="h5" style={{ cursor: 'pointer' }} onClick={() => toggleOpen(!isOpen)}>
          <span className="font-weight-semibold">Slot - </span>
          {value.id || 'New Prebid Slot'}
        </CardTitle>
        <CardActions>
          <ActionButton
            icon={`chevron-${isOpen ? 'up' : 'down'}`}
            onClick={() => toggleOpen(!isOpen)}
          />
          <ActionButton icon="times" onClick={() => onRemove()} />
        </CardActions>
      </CardHeading>
      <CardAccordianContent pose={isOpen ? 'opened' : 'closed'} style={{ overflow: 'hidden' }}>
        <CardContent>
          <fieldset className="mb-3">
            <legend className="text-uppercase font-size-sm font-weight-bold">
              {value.id || 'New Prebid Slot'}
            </legend>

            <FormRow
              label="Ad identifier"
              name={`${name}.id`}
              value={value.id}
              error={error?.id}
              disabled={!!readOnly}
            />

            <FormRow
              label="Inventory type"
              as={Select}
              name={`${name}.type`}
              value={value.type ?? '1'}
              error={error?.type}
              disabled={!!readOnly}
              multiple={false}
              availableData={[
                {
                  id: '1',
                  text: 'display + outstream',
                },
                {
                  id: '2',
                  text: 'display',
                },
                {
                  id: '3',
                  text: 'instream',
                },
                {
                  id: '4',
                  text: 'outstream',
                },
              ]}
            />

            {biddersInfo.map(bidder => {
              const label = createLabel(bidder, value.enabled);

              return (
                <FormRow
                  label={label}
                  key={bidder.key}
                  as={Toggle}
                  id={`${name}.bidders.${bidder.key}`}
                  name={`${name}.bidders`}
                  value={true}
                  checked={
                    (!readOnly && value.bidders.includes(bidder.key)) ||
                    (readOnly && enabledBidders.has(bidder.key))
                  }
                  onChange={checked => {
                    let bidders = new Set(value.bidders);

                    if (checked) {
                      bidders.add(bidder.key);
                    } else {
                      bidders.delete(bidder.key);
                    }

                    return Array.from(bidders);
                  }}
                  disabled={readOnly || !enabledBidders.has(bidder.key)}
                  disabledTooltip={
                    readOnly ? undefined : `First enable "${bidder.name}"in Prebid bidder settings`
                  }
                />
              );
            })}
          </fieldset>
        </CardContent>
      </CardAccordianContent>
    </CardContainer>
  );
};

export default PrebidSlot;
