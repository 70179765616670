import React from 'react';
import { settingsRequiredWhenEnabled } from '../../../../utils';
import { gql } from '@apollo/client';
import logo from '../../../../assets/images/plugins/lazyload.png';
import { createSlotTag } from '../../SettingsForm/utils';
import { FieldArray } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormRow, { Badge, FormGroup, FormLabel } from '../../../../components/Form';
import { FormikSelect as Select } from '../../../../components/Form/Select';
import { FormikInput as Input } from '../../../../components/Form/Input';

export const id = 'lazyload';
export const name = 'Lazyload';
export const description =
  'Configure lazyload behaviour for your adserver. Improving ad impressions.';
export const image = logo;

export const genSchema = yup =>
  yup.object().shape({
    // id: settingsRequiredWhenEnabled(yup.string().label('ID')),
    settings: settingsRequiredWhenEnabled(
      yup.array().of(
        yup.object({
          viewport: yup.number().min(0).integer().label('Device width'),
          fetchViewport: yup.number().min(0).label('Fetch percentage'),
          renderViewport: yup.number().min(0).label('Render percentage'),
        })
      )
    ),
    groups: yup
      .array()
      .of(yup.array().of(yup.string().trim().required().label('Group')).min(1).label('Group')),
    exludedSlots: yup.array().of(yup.string().label('Slots to exclude')),
  });

/**
 * @type {import('../../../../../types/settings').FormSection<{ exludedSlots: Array<string> }>}
 */
export const SettingsForm = ({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) => {
  console.log(errors);
  return (
    <>
      <LazyloadSettings
        name="settings"
        value={value.settings}
        inheritedValue={inheritedValue.settings}
        disabled={!isEnabled}
        error={errors.settings}
        showInheritanceBadge={showInheritanceBadge}
      />
      <LazyloadGroup
        name="groups"
        value={value.groups}
        inheritedValue={inheritedValue.groups}
        disabled={!isEnabled}
        error={errors.groups}
        showInheritanceBadge={showInheritanceBadge}
      />
      <FormRow
        as={Select}
        label="Slots to exclude"
        name="exludedSlots"
        createTag={createSlotTag}
        value={value.exludedSlots}
        inheritedValue={inheritedValue.exludedSlots.inheritedValue}
        padding="small"
        disabled={!isEnabled}
        error={errors.exludedSlots}
        showInheritanceBadge={showInheritanceBadge}
      />
    </>
  );
};

function LazyloadSettings({ name, value, inheritedValue, showInheritanceBadge, disabled, error }) {
  const stableKeys = React.useRef([]);

  return (
    <FormGroup>
      <div className="col-lg-2">
        <FormLabel>Lazyload settings</FormLabel>
        {showInheritanceBadge ? (
          <Badge name={name} value={value} inherited={inheritedValue.inheritedValue} />
        ) : null}
      </div>
      <div className="col-lg-8">
        <FieldArray
          name={name}
          render={({ push, remove }) => {
            let children = [];

            const valuesToShow = value ?? inheritedValue.inheritedValue;
            const isInherited = valuesToShow === inheritedValue.inheritedValue;
            if (valuesToShow) {
              valuesToShow.forEach(({ viewport, fetchViewport, renderViewport }, index) => {
                if (!stableKeys.current[index]) {
                  stableKeys.current[index] = `${index}_${new Date().getTime()}`;
                }

                children.push(
                  <FormGroup key={stableKeys.current[index]}>
                    <div className="col-sm-8">
                      <div className="row">
                        <div className="col-sm-4">
                          <FormRow
                            direction="col"
                            as={Input}
                            disabled={disabled || isInherited}
                            label="Device width"
                            name={`${name}.${index}.viewport`}
                            value={viewport}
                            type="number"
                            min="0"
                          />

                          <div>
                            {error?.length && error[index]?.viewport ? (
                              <span className="form-text text-danger">{error[index].viewport}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <FormRow
                            direction="col"
                            as={Input}
                            type="number"
                            disabled={disabled || isInherited}
                            label="The distance before we fetch the ad"
                            name={`${name}.${index}.fetchViewport`}
                            value={fetchViewport}
                            min="0"
                          />

                          <div>
                            {error?.length && error[index]?.fetchViewport ? (
                              <span className="form-text text-danger">
                                {error[index].fetchViewport}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <FormRow
                            direction="col"
                            as={Input}
                            type="number"
                            disabled={disabled || isInherited}
                            label="The distance before we render the ad"
                            name={`${name}.${index}.renderViewport`}
                            value={renderViewport}
                            min="0"
                          />

                          <div>
                            {error?.length && error[index]?.renderViewport ? (
                              <span className="form-text text-danger">
                                {error[index].renderViewport}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-1 justify-content-center align-items-end d-flex">
                      <button
                        type="button"
                        disabled={disabled || isInherited}
                        className="btn btn-link"
                        style={{ color: 'inherit' }}
                        onClick={() => {
                          if (disabled || isInherited) {
                            return;
                          }

                          stableKeys.current.splice(index, 1);
                          remove(index);
                        }}
                      >
                        <i className="icon-cross2" />
                      </button>
                    </div>
                  </FormGroup>
                );
              });
            }

            return (
              <>
                {children}
                <div>
                  <button
                    type="button"
                    className="btn bg-blue btn-labeled btn-labeled-right ml-auto mr-2"
                    style={{ opacity: disabled ? 0.5 : 1 }}
                    onClick={() => {
                      !disabled &&
                        push({
                          viewport: 0,
                          fetchViewport: 0,
                          renderViewport: 0,
                        });
                    }}
                  >
                    <b>
                      <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                    </b>{' '}
                    Add new viewport
                  </button>
                </div>
              </>
            );
          }}
        />
      </div>
    </FormGroup>
  );
}

function LazyloadGroup({ name, value, inheritedValue, showInheritanceBadge, disabled, error }) {
  const stableKeys = React.useRef([]);

  return (
    <FormGroup>
      <div className="col-lg-2">
        <FormLabel>Groups</FormLabel>
        {showInheritanceBadge ? (
          <Badge name={name} value={value} inherited={inheritedValue.inheritedValue} />
        ) : null}
      </div>
      <div className="col-lg-8">
        <FieldArray
          name={name}
          render={({ push, remove }) => {
            let children = [];

            const valuesToShow = value ?? inheritedValue.inheritedValue;
            const isInherited = valuesToShow === inheritedValue.inheritedValue;

            if (valuesToShow) {
              valuesToShow.forEach((group, index) => {
                if (!stableKeys.current[index]) {
                  stableKeys.current[index] = `${index}_${new Date().getTime()}`;
                }

                children.push(
                  <FormGroup key={stableKeys.current[index]}>
                    <div className="col-sm-8">
                      <FormRow
                        as={Select}
                        label={`Group #${index}`}
                        name={`${name}.${index}`}
                        createTag={createSlotTag}
                        value={group}
                        padding="small"
                        disabled={disabled || isInherited}
                      />
                      <div>
                        {error?.length && error[index] ? (
                          <span className="form-text text-danger">{error[index]}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-sm-1 justify-content-center d-flex">
                      <button
                        type="button"
                        disabled={disabled || isInherited}
                        className="btn btn-link"
                        style={{ color: 'inherit' }}
                        onClick={() => {
                          if (disabled || isInherited) {
                            return;
                          }

                          stableKeys.current.splice(index, 1);
                          remove(index);
                        }}
                      >
                        <i className="icon-cross2" />
                      </button>
                    </div>
                  </FormGroup>
                );
              });
            }

            return (
              <>
                {children}
                <div>
                  <button
                    type="button"
                    className="btn bg-blue btn-labeled btn-labeled-right ml-auto mr-2"
                    style={{ opacity: disabled ? 0.5 : 1 }}
                    onClick={() => {
                      !disabled && push([]);
                    }}
                  >
                    <b>
                      <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                    </b>{' '}
                    Add new group
                  </button>
                </div>
              </>
            );
          }}
        />
      </div>
    </FormGroup>
  );
}

export const FRAGMENT_PLUGIN_SETTINGS = {
  name: 'LazyloadPluginSettings',
  fragment: gql`
    fragment LazyloadPluginSettings on LazyloadPlugin {
      settings {
        value {
          viewport
          fetchViewport
          renderViewport
        }
        inheritedValue {
          viewport
          fetchViewport
          renderViewport
        }
      }
      groups {
        value
        inheritedValue
      }
      exludedSlots {
        value
        inheritedValue
      }
    }
  `,
};
