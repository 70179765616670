import { useReducer, useCallback } from 'react';

function fetchReducer(state, action) {
  if (action.type === 'FETCH_START') {
    return {
      called: true,
      loading: true,
      data: null,
    };
  }

  if (action.type === 'FETCH_DONE') {
    return {
      called: true,
      loading: false,
      result: action.result,
    };
  }

  return state;
}

export function useDynamicQuery(client) {
  const [state, dispatch] = useReducer(fetchReducer, {
    called: false,
    loading: false,
    result: {},
  });

  const executeQuery = useCallback(function executeQuery(query, options) {
    dispatch('FETCH_START');

    client
      .query({
        query,
        ...options,
      })
      .then(result => {
        dispatch({ type: 'FETCH_DONE', result });
      });
  }, []);

  return {
    ...state,
    executeQuery,
  };
}
