import React, { useState, useEffect } from 'react';
// import { navigate, useLocation, useParams, useMatch } from '@reach/router';
import { useAppContext } from '../../../providers/App';
import { useParams } from '@reach/router';
import PluginCard from './PluginCard';
import PluginSettingsModal from './PluginSettingsModal';

const PluginVendorCard = ({ plugin, isEnabled, showInheritanceBadge }) => {
  const { organisation } = useAppContext();
  const [pluginInfo, setPluginInfo] = useState();
  const params = useParams();
  // const location = useLocation();
  // const matchesUrl = useMatch(`plugins/${pluginInfo?.id}`);
  // const isModalOpen = !!(pluginInfo?.id && matchesUrl);
  const [isModalOpen, toggleModal] = useState(false);

  useEffect(() => {
    import(`../forms/plugins/${plugin}`).then(info => {
      setPluginInfo(info);
    });
  }, [plugin]);

  if (!pluginInfo) {
    return null;
  }

  return (
    <>
      <PluginCard
        name={pluginInfo.name}
        image={pluginInfo.image}
        description={pluginInfo.description}
        enabled={isEnabled}
        openSettings={() => {
          toggleModal(true);
          // navigate(
          //   `/${location.pathname
          //     .split('/')
          //     .filter(Boolean)
          //     .join('/')}/plugins/${plugin}/`,
          //   {
          //     state: { noScroll: true },
          //   }
          // );
        }}
      />
      <PluginSettingsModal
        siteId={showInheritanceBadge ? params.siteId : organisation.id}
        plugin={pluginInfo}
        onDismiss={() => {
          // navigate(location.pathname.replace(`plugins/${plugin}/`, ''))
          toggleModal(false);
        }}
        isOpen={!!isModalOpen}
        showInheritanceBadge={showInheritanceBadge}
      />
    </>
  );
};

export default PluginVendorCard;
