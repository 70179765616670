import React from 'react';
import FormRow from '../../../../components/Form';
import { settingsRequiredWhenEnabled } from '../../../../utils';
import { gql } from '@apollo/client';
import logo from '../../../../assets/images/bidders/aps.jpg';
import { FormikToggle as Toggle } from '../../../../components/Form/Toggle';

export const id = 'aps';
export const name = 'Amazon Publisher Services';
export const mutationId = 'Aps';
export const description =
  'Enrich your supply with billions of exclusive insights from Amazon Ads.';
export const image = logo;

export const genSchema = Yup =>
  Yup.object()
    .default(undefined)
    .shape({
      pubId: settingsRequiredWhenEnabled(Yup.string().label('Publication ID')),
      deals: Yup.boolean().label('Use deals'),
    });

/**
 * @type {import('../../../../../types/settings').FormSection<{}>}
 */
export const SettingsForm = function ApsSettingsForm({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) {
  return (
    <>
      <FormRow
        name="pubId"
        label="Publication ID"
        value={value.pubId}
        inheritedValue={inheritedValue.pubId.inheritedValue ?? undefined}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors.pubId}
      />

      <FormRow
        name="deals"
        label="Use deals"
        as={Toggle}
        value={true}
        checked={value.deals}
        inheritedChecked={inheritedValue.deals.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        error={errors.deals}
        disabled={!isEnabled}
      />
    </>
  );
};

export const FRAGMENT_BIDDER_SETTINGS = {
  name: 'ApsBidderSettings',
  fragment: gql`
    fragment ApsBidderSettings on ApsBidder {
      pubId {
        value
        inheritedValue
      }
      deals {
        value
        inheritedValue
      }
    }
  `,
};
