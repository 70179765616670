import React from 'react';
import FormRow from '../../../../components/Form';
import { settingsRequiredWhenEnabled } from '../../../../utils';
import { gql } from '@apollo/client';
import logo from '../../../../assets/images/plugins/consent.png';
import { FormikSelect as Select } from '../../../../components/Form/Select';

export const id = 'consent';
export const name = 'Consent';
export const description =
  'Consent plugin allows you to modify timeouts and choose the consent vendor.';
export const image = logo;

export const genSchema = yup =>
  yup.object().shape({
    timeout: settingsRequiredWhenEnabled(yup.number().allowEmptyString().label('Consent timeout')),
    vendor: settingsRequiredWhenEnabled(yup.string().oneOf(['didomi', 'other'])),
  });

/**
 * @type {import('../../../../../types/settings').FormSection<{ timeout: number, url: string }>}
 */
export const SettingsForm = ({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) => {
  return (
    <>
      <FormRow
        name="timeout"
        label="Wait for consent timeout"
        value={value.timeout}
        inheritedValue={inheritedValue.timeout.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors['timeout']}
      />
      <FormRow
        as={Select}
        name="vendor"
        label="Vendor"
        value={value.vendor}
        inheritedValue={inheritedValue.vendor.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors['vendor']}
        multiple={false}
        availableData={['didomi', 'other']}
      />
    </>
  );
};

export const FRAGMENT_PLUGIN_SETTINGS = {
  name: 'ConsentPluginSettings',
  fragment: gql`
    fragment ConsentPluginSettings on ConsentPlugin {
      timeout {
        value
        inheritedValue
      }
      vendor {
        value
        inheritedValue
      }
    }
  `,
};
