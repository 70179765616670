import * as React from 'react';
import { CardContainer, CardHeading, CardTitle, CardContent } from '../../../components/Card';

const BlockCard: React.FunctionComponent<{
  title: string | React.ReactNode;
  id: string;
  headerElements?: React.ReactNode;
}> = function BlockCard({ title, children, headerElements, ...props }) {
  return (
    <CardContainer {...props}>
      <CardHeading variation="light" inline={!!headerElements}>
        <CardTitle as="h5">{title}</CardTitle>
        {headerElements}
      </CardHeading>
      <CardContent>{children}</CardContent>
    </CardContainer>
  );
};

export default BlockCard;
