import React, { createContext, useState, useContext } from 'react';
// import { useAppContext } from './App';

/**
 * @typedef Sidebar
 * @property {boolean} visible
 * @property {string|null} component
 * @property {Function} toggleSidebar
 */

/** @type {React.Context<Sidebar} */
const SidebarContext = createContext({
  visible: false,
  component: null,
  toggleSidebar: () => {},
});

export const SidebarProvider = ({ children }) => {
  // const { collapseNavigation } = useAppContext();
  const [isVisible, setVisible] = useState(false);
  const [component, setComponent] = useState(null);

  /**
   * @param {string|null} component
   */
  const toggleSidebar = component => {
    // collapseNavigation(!!component);
    setVisible(!!component);
    setComponent(component);
  };

  const contextValue = {
    visible: isVisible,
    component,
    toggleSidebar,
  };

  return <SidebarContext.Provider value={contextValue}>{children}</SidebarContext.Provider>;
};

export const useSidebar = () => {
  return useContext(SidebarContext);
};
