let yupPromise;

export function getYup() {
  if (!yupPromise) {
    yupPromise = import('yup').then(yup => {
      yup.addMethod(yup.number, 'allowEmptyString', function allowEmptyString() {
        return (
          this.default(undefined)
            // .nullable(true)
            .transform(function allowEmptyStringTransform(v, originalvalue) {
              if (!isNaN(v)) {
                return v;
              }

              return originalvalue === '' || originalvalue === null ? undefined : v;
            })
        );
      });

      return yup;
    });
  }

  return yupPromise;
}
