import React from 'react';
import FormRow from '../../../../components/Form';
import { settingsRequiredWhenEnabled } from '../../../../utils';
import MappingTable from '../../../../components/Form/MappingTable';
import { FormikSelect as Select } from '../../../../components/Form/Select';
import { gql } from '@apollo/client';
import logo from '../../../../assets/images/plugins/video.png';

export const id = 'video';
export const name = 'Video';
export const description = 'Cutomize your video settings';
export const image = logo;

export const sizesArrayToStringArray = sizes =>
  sizes ? sizes.map(values => (Array.isArray(values) ? values.join('x') : values)) : [];

export const displayNamesToValues = (displayNames = []) => {
  return displayNames.map(displayName => {
    let [, width, height] = displayName.match(/(\d+)x(\d+)/);

    return {
      width: Number(width),
      height: Number(height),
    };
  });
};

export const createFormatTag = ({ term }) => {
  if (!/(\d+x\d+)/.test(term)) {
    // Return null to disable tag creation
    // Filter for format numberxnumber input
    return null;
  }

  return {
    id: term,
    text: term,
  };
};

export function createSizeTag({ term }) {
  if (!/(\d+x\d+)/.test(term)) {
    // Return null to disable tag creation
    // Filter for format numberxnumber input
    return null;
  }

  return {
    id: term,
    text: term,
  };
}

export const genSchema = yup =>
  yup.object().shape({
    language: yup
      .array()
      .of(
        yup.object().shape({
          targeting: yup.array().of(
            yup.object().shape({
              key: yup.string(),
              value: yup.mixed(),
            })
          ),
          name: yup.string(),
          value: yup.mixed(),
        })
      )
      .label('Language'),
    sizes: settingsRequiredWhenEnabled(
      yup
        .array()
        .of(
          yup.object().shape({
            width: yup.number(),
            height: yup.number(),
          })
        )
        .label('Sizes')
    ),
  });

/**
 * @type {import('../../../../../types/settings').FormSection<{ id: string, url: string }>}
 */
export const SettingsForm = ({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) => {
  let dedupedSizes = undefined;
  if (value.sizes) {
    dedupedSizes = Array.from(new Set(value.sizes.map(size => `${size.width}x${size.height}`)));
  }

  let inheritedSizes = undefined;
  if (inheritedValue.sizes.inheritedValue) {
    inheritedSizes = Array.from(
      new Set(
        (inheritedValue.sizes.inheritedValue || []).map(size => `${size.width}x${size.height}`)
      )
    );
  }

  return (
    <>
      <FormRow
        name="language"
        label="Language"
        value={value.language}
        inheritedValue={inheritedValue.language.inheritedValue ?? undefined}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors.language}
        direction="column"
        as={MappingTable}
      />

      <FormRow
        as={Select}
        label="Player Sizes"
        name="sizes"
        createTag={createSizeTag}
        value={dedupedSizes}
        inheritedValue={inheritedSizes}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        onChange={val => displayNamesToValues(val)}
        error={errors.sizes}
      />
    </>
  );
};

export const FRAGMENT_PLUGIN_SETTINGS = {
  name: 'VideoPluginSettings',
  fragment: gql`
    fragment VideoPluginSettings on VideoPlugin {
      language {
        value {
          name
          value
          targeting {
            key
            value
          }
        }
        inheritedValue {
          name
          value
          targeting {
            key
            value
          }
        }
      }
      sizes {
        value {
          width
          height
        }
        inheritedValue {
          width
          height
        }
      }
    }
  `,
};
