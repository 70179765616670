import React, { useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql } from '@apollo/client';
import BlockCard from './BlockCard';
import InheritanceBadge from '../../../components/Form/InheritanceBadge';

import PrebidSlot from './PrebidSlot';
import PrebidBidderCard from './PrebidBidderCard';
import PrebidUserIdCard from './PrebidUserIdCard';
import { generateFormName } from '../../../utils';
import { schema as prebidSlotSchema } from './PrebidSlot';
// import { memoize } from 'lodash';

import { settingsMetadata } from '../../../data/settings';

const { prebid: metadata } = settingsMetadata;
const {
  prebidslots: slotsMedadata,
  prebidbidders: biddersMetadata,
  prebidusersyncs: userIdsMetadata,
} = metadata.subsettings;

const availableBidders = require
  .context('../forms/bidders/', false, /\.js$/)
  .keys()
  .map(file => file.replace('./', '').replace('.js', ''));
const availableUserIds = require
  .context('../forms/userIds/', false, /\.js$/)
  .keys()
  .map(file => file.replace('./', '').replace('.js', ''));

export const schema = yup => {
  return yup.object().shape({
    slots: yup.array().of(prebidSlotSchema(yup)).nullable(true).default(null),
  });
};

/**
 * @typedef PrebidSlots
 * @property {string} code
 * @property {[number, number]} fallbackSizes
 * @property {string[]} bidders
 */

/**
 * @typedef PrebidSettings
 * @property {boolean} enabled
 * @property {PrebidSlots[]} slots
 * @property {Object} bidders
 */

const Badge = ({ name, value, inherited, reset }) => {
  // TODO refactor this
  const formik = useFormikContext();

  const showClearButton = typeof value !== 'undefined' && typeof inherited !== 'undefined';

  return (
    <span className="ml-2 align-self-center">
      <InheritanceBadge value={value} inherited={inherited} />
      {showClearButton && (
        <button
          className="sl-2 btn btn-link btn-icon"
          data-tip="Clear value"
          data-place="right"
          type="button"
          onClick={() => {
            formik.setFieldValue(name, undefined);
            formik.setFieldTouched(name, false);
            reset && reset();
          }}
        >
          <FontAwesomeIcon icon="redo-alt" />
        </button>
      )}
    </span>
  );
};

/**
 * @type {import('../../../../types/settings').FormSection<PrebidSettings>}
 */
const PrebidBlock = ({ name, value, inheritedValue, showInheritanceBadge, errors }) => {
  const generateName = generateFormName(name);
  const { slots = [] } = value || {};
  const enabledBidders = React.useMemo(() => {
    const result = new Set();
    inheritedValue.bidders.forEach(bidder => {
      if (bidder.enabled.computedValue) {
        result.add(bidder.name);
      }
    });

    return result;
  }, [inheritedValue.bidders]);
  const enabledUserIds = React.useMemo(() => {
    const result = new Set();
    inheritedValue.userIds.forEach(userId => {
      if (userId.enabled.computedValue) {
        result.add(userId.name);
      }
    });

    return result;
  }, [inheritedValue.userIds]);
  const [showInheritValue, toggleShowInheritValue] = useState(!value?.slots?.length);
  const prebidSlotsTitle = (
    <>
      {slotsMedadata.title}
      {showInheritanceBadge && (
        <Badge
          name={generateName('slots')}
          value={value?.slots ?? undefined}
          inherited={inheritedValue?.slots ?? undefined}
          reset={() => toggleShowInheritValue(true)}
        />
      )}
    </>
  );

  const prebidHeaderElements = (
    <ul className="list-inline list-inline-dotted mb-0">
      {Object.keys(metadata.subsettings).map(key => {
        const subsetting = metadata.subsettings[key];
        return (
          <li className="list-inline-item" key={key}>
            <a href={subsetting.anchor}>{subsetting.title.replace('Prebid ', '')}</a>
          </li>
        );
      })}
    </ul>
  );

  return (
    <BlockCard
      title={metadata.title}
      id={metadata.domId}
      style={{ scrollMarginTop: '55px' }}
      headerElements={prebidHeaderElements}
    >
      <BlockCard title={biddersMetadata.title} id={biddersMetadata.domId}>
        <div className={`row ${biddersMetadata.domId}`}>
          {availableBidders.map(bidder => {
            return (
              <div key={bidder}>
                <PrebidBidderCard
                  bidder={bidder}
                  isEnabled={enabledBidders.has(bidder)}
                  showInheritanceBadge={showInheritanceBadge}
                />
              </div>
            );
          })}
        </div>
      </BlockCard>
      <BlockCard title={userIdsMetadata.title} id={userIdsMetadata.domId}>
        <div className={`row ${userIdsMetadata.domId}`}>
          {availableUserIds.map(userId => {
            return (
              <div key={userId}>
                <PrebidUserIdCard
                  userId={userId}
                  isEnabled={enabledUserIds.has(userId)}
                  showInheritanceBadge={showInheritanceBadge}
                />
              </div>
            );
          })}
        </div>
      </BlockCard>

      <BlockCard title={prebidSlotsTitle} id={slotsMedadata.domId}>
        <FieldArray
          name={generateName('slots')}
          render={({ push, remove }) => {
            return (
              <>
                <div>
                  {slots &&
                    slots.map((slot, index) => {
                      return (
                        <PrebidSlot
                          key={index}
                          enabledBidders={
                            showInheritanceBadge ? enabledBidders : new Set(availableBidders)
                          }
                          availableBidders={availableBidders}
                          name={generateName(`slots.${index}`)}
                          value={slot}
                          onRemove={() => remove(index)}
                          error={errors?.slots?.[index]}
                          isOpen={slot.code === ''}
                        />
                      );
                    })}
                  {showInheritValue &&
                    inheritedValue?.slots?.inheritedValue?.map((slot, index) => {
                      return (
                        <PrebidSlot
                          key={index}
                          enabledBidders={new Set(slot.bidders)}
                          availableBidders={availableBidders}
                          name={generateName(`slots.${index}`)}
                          value={slot}
                          readOnly
                        />
                      );
                    })}
                </div>
                <button
                  type="button"
                  className="btn bg-blue btn-labeled btn-labeled-right ml-auto mr-2"
                  onClick={() => {
                    toggleShowInheritValue(false);
                    push({
                      code: '',
                      sizes: [],
                      bidders: [],
                    });
                  }}
                >
                  <b>
                    <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                  </b>{' '}
                  Add New Prebid Slot
                </button>
              </>
            );
          }}
        />
      </BlockCard>
    </BlockCard>
  );
};

export const FRAGMENT_PREBID_SETTINGS = {
  name: 'PrebidSettings',
  fragment: gql`
    fragment PrebidSettings on Settings {
      prebid {
        bidders {
          enabled {
            computedValue
          }
          name
        }
        userIds {
          enabled {
            computedValue
          }
          name
        }
        slots {
          value {
            id
            type
            bidders
          }
          inheritedValue {
            id
            type
            bidders
          }
        }
      }
    }
  `,
};

export default React.memo(PrebidBlock);
