import React from 'react';

/**
 * @type {React.FunctionComponent<{ title: string, as: React.Component|string}>}
 */
const PageTitle = ({ title, children, as = 'h5' }) => {
  let moreButton;
  let headerElements;
  if (React.Children.count(children)) {
    moreButton = (
      <button className="header-elements-toggle text-default d-md-none">
        <i className="icon-more" />
      </button>
    );

    headerElements = <div className="header-elements d-none">{children}</div>;
  }

  const headline = React.createElement(as, null, title);

  return (
    <div className="page-header-content header-elements-md-inline">
      <div className="page-title d-flex">
        {headline}

        {moreButton}
      </div>

      {headerElements}
    </div>
  );
};

export default PageTitle;
