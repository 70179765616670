import React from 'react';
import { FieldArray } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { displayNamesToValues, createFormatTag } from './utils';
import { FormGroup, FormLabel } from '../../../components/Form';
import { FormikInput as Input } from '../../../components/Form/Input';
import { FormikSelect as Select } from '../../../components/Form/Select';

type SizeMapping = {
  viewportOffset: number;
  sizes: Array<{
    displayName: string;
    fluid: boolean;
    width: number | null;
    height: number | null;
  }>;
};

const SizeMappings: React.FunctionComponent<{
  name: string;
  value: Array<SizeMapping>;
  disabled: boolean;
}> = function SizeMappings({ name, value = [], disabled }) {
  const stableKeys = React.useRef<Array<string>>([]);

  return (
    <FormGroup>
      <div className="col-lg-2">
        <FormLabel>Sizemapping</FormLabel>
      </div>
      <div className="col-lg-8">
        <FieldArray
          name={name}
          render={({ push, remove }) => {
            let children = [];
            if (value) {
              value.forEach(({ viewportOffset, sizes }, index) => {
                if (!stableKeys.current[index]) {
                  stableKeys.current[index] = `${index}_${new Date().getTime()}`;
                }

                children.push(
                  <FormGroup key={stableKeys.current[index]}>
                    <div className="col-sm-1">
                      <Input
                        name={`${name}.${index}.viewportOffset`}
                        value={viewportOffset}
                        disabled={disabled}
                        style={{ minWidth: '55px' }}
                      />
                    </div>
                    <div className="col-sm-10">
                      <Select
                        name={`${name}.${index}.sizes`}
                        value={(sizes || []).map(size => size.displayName)}
                        onChange={val => {
                          return displayNamesToValues(val);
                        }}
                        disabled={disabled}
                        placeholder="Add Size"
                        createTag={createFormatTag}
                        tags
                      />
                    </div>
                    <div className="col-sm-1 justify-content-center d-flex">
                      <button
                        type="button"
                        disabled={disabled}
                        className="btn btn-link"
                        style={{ color: 'inherit' }}
                        onClick={() => {
                          if (disabled) {
                            return;
                          }

                          stableKeys.current.splice(index, 1);
                          remove(index);
                        }}
                      >
                        <i className="icon-cross2" />
                      </button>
                    </div>
                  </FormGroup>
                );
              });
            }

            return (
              <>
                {children}
                <div>
                  <button
                    type="button"
                    className="btn bg-blue btn-labeled btn-labeled-right ml-auto mr-2"
                    style={{ opacity: disabled ? 0.5 : 1 }}
                    onClick={() => {
                      !disabled &&
                        push({
                          breakpoint: '',
                          sizes: [],
                        });
                    }}
                  >
                    <b>
                      <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                    </b>{' '}
                    Add New Sizemapping
                  </button>
                </div>
              </>
            );
          }}
        />
      </div>
    </FormGroup>
  );
};

export default SizeMappings;
