import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useAppContext } from '../providers/App';
import Header from '../components/Header';
// import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
// import SecondarySidebar from '../components/SecondarySidebar';
// import { useSidebar } from '../providers/Sidebar';
import '../assets/scss/site.scss';

export { FRAGMENT_DEFAULT_SITE } from '../components/Sidebar';
export { FRAGMENT_USER } from '../components/Header';

const DefaultLayout = ({ children, me }) => {
  const { organisation } = useAppContext();
  useEffect(() => {
    import('../assets/js/app').then(({ default: App }) => {
      App.initBeforeLoad();
      App.initCore();
      App.initAfterLoad();
    });
  }, []);

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,300,100,500,700,900"
          rel="stylesheet"
        />
      </Helmet>

      <Header user={me} />

      <div className="page-content" id="container">
        <Sidebar defaultSite={organisation.defaultSite} />

        {children}
      </div>
    </>
  );
};

export default DefaultLayout;
