import React from 'react';
import { Helmet } from 'react-helmet';

import PageTitle from '../../components/PageTitle';
import Breadcrumb from '../../components/Breadcrumb';
import Footer from '../../components/Footer';
// import Loader from '../../components/Loader';
// import DashboardScreen from '../../screens/dashboard';

// import d1 from '../../assets/dummycontent/dashboard1';
// import d2 from '../../assets/dummycontent/dashboard2';

const Dashboard = () => {
  // const [dashboardData, setDashboardData] = useState();

  // useEffect(() => {
  //   // setDashboardData({ d1, d2 });
  // }, [d1, d2]);

  return (
    <div className="content-wrapper">
      <Helmet title="Dashboard" />
      <div className="page-header page-header-light">
        <PageTitle title="Dashboard" as="h5" />

        <Breadcrumb items={[['Dashboard', '/']]} />
      </div>

      <div className="content">
        {/* {!dashboardData && (
          <div style={{ height: '300px', position: 'relative' }}>
            <Loader />
          </div>
        )}

        {dashboardData && <DashboardScreen dashboardData={dashboardData} />} */}
      </div>

      <Footer />
    </div>
  );
};

export default Dashboard;
