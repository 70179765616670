import * as yup from 'yup';
import delve from 'dlv';

function shouldFieldBeRequired(field, fieldValue, schema, options) {
  if (!options.path) {
    return false;
  }

  const namespace = options.path.split('.').slice(0, -1);
  namespace.push(field);

  const isEnabled =
    fieldValue ??
    delve(options.context, `inheritedData.${namespace.join('.')}.inheritedValue`, false);
  const inheritedValue = delve(
    options.context,
    `inheritedData.${options.path}.inheritedValue`,
    undefined
  );

  if (isEnabled && !inheritedValue) {
    return true;
  }

  return false;
}

export function settingsRequiredWhenEnabled(operator, field = 'enabled') {
  if (operator.type === 'object') {
    return yup.object().when('enabled', (fieldValue, schema, options) => {
      if (!shouldFieldBeRequired(field, fieldValue, schema, options)) {
        return operator;
      }

      const requiredSchemaMembers = {};
      operator._nodes.forEach(fieldName => {
        requiredSchemaMembers[fieldName] = operator.fields[fieldName].required();
      });

      return yup.object().shape(requiredSchemaMembers);
    });
  }

  return operator.when(field, (fieldValue, schema, options) => {
    if (shouldFieldBeRequired(field, fieldValue, schema, options)) {
      return schema.required();
    }

    return schema;
  });
}

export function generateFormName(name) {
  return key => [name, key].filter(Boolean).join('.');
}

export function fillFieldsWithDefaultNull(values, schema) {
  Object.keys(schema.fields).forEach(fieldName => {
    // const field = schema.fields[fieldName];
    if (typeof values[fieldName] === 'undefined') {
      values[fieldName] = null;
      return;
    }

    if (schema.fields[fieldName].type === 'object' && Object.keys(values[fieldName]).length === 0) {
      values[fieldName] = null;
      return;
    }

    if (schema.fields[fieldName].type === 'object') {
      values[fieldName] = fillFieldsWithDefaultNull(values[fieldName], schema.fields[fieldName]);
      return;
    }
  });

  return values;
}
