import React from 'react';
import FormRow from '../../../../components/Form';
import MappingTable from '../../../../components/Form/MappingTable';
import { settingsRequiredWhenEnabled } from '../../../../utils';
import { gql } from '@apollo/client';
import logo from '../../../../assets/images/bidders/showheroes.jpg';

export const id = 'showheroesBs';
export const name = 'ShowHeroes';
export const mutationId = 'ShowHeroesBs';
export const description =
  'ShowHeroes offers a powerful combination of editorial video content with contextual playback and sustainable monetisation into a compelling multiscreen ...';
export const image = logo;

export const genSchema = Yup =>
  Yup.object()
    .default(undefined)
    .shape({
      unitId: settingsRequiredWhenEnabled(
        Yup.array()
          .of(
            Yup.object().shape({
              targeting: Yup.array().of(
                Yup.object().shape({
                  key: Yup.string(),
                  value: Yup.mixed(),
                })
              ),
              name: Yup.string(),
              value: Yup.mixed(),
            })
          )
          .label('Unit ID')
      ),
    });

/**
 * @type {import('../../../../../types/settings').FormSection<{}>}
 */
export const SettingsForm = function ShowheroesSettingsForm({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) {
  return (
    <>
      <FormRow
        name="unitId"
        label="Unit ID"
        value={value.unitId}
        inheritedValue={inheritedValue.unitId.inheritedValue ?? undefined}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors.unitId}
        direction="column"
        as={MappingTable}
      />
    </>
  );
};

export const FRAGMENT_BIDDER_SETTINGS = {
  name: 'ShowHeroesBidderSettings',
  fragment: gql`
    fragment ShowHeroesBidderSettings on ShowHeroesBsBidder {
      unitId {
        value {
          name
          value
          targeting {
            key
            value
          }
        }
        inheritedValue {
          name
          value
          targeting {
            key
            value
          }
        }
      }
    }
  `,
};
