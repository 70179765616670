import React, { useState, useEffect } from 'react';
// import { navigate, useLocation, useParams, useMatch } from '@reach/router';
import { useParams } from '@reach/router';
import { useAppContext } from '../../../providers/App';
import PluginCard from './PluginCard';
import PrebidUserIdModal from './PrebidUserIdModal';

const PrebidUserIdCard = ({ userId, isEnabled, showInheritanceBadge }) => {
  const { organisation } = useAppContext();
  const [userIdInfo, setUserIdInfo] = useState();
  const params = useParams();
  // const location = useLocation();
  // const matchesUrl = useMatch(`bidders/${bidderInfo?.id}`);
  // const isModalOpen = !!(bidderInfo?.id && matchesUrl);
  const [isModalOpen, toggleModal] = useState(false);

  useEffect(() => {
    import(
      /* webpackExports ["id", "name", "image", "description"] */
      /* webpackChunkName: "bidders/[request]" */
      `../forms/userIds/${userId}`
    ).then(info => {
      setUserIdInfo(info);
    });
  }, [userId]);

  if (!userIdInfo) {
    return null;
  }

  return (
    <>
      <PluginCard
        name={userIdInfo.name}
        image={userIdInfo.image}
        description={userIdInfo.description}
        enabled={isEnabled}
        openSettings={() => {
          toggleModal(true);
          // navigate(
          //   `/${location.pathname
          //     .split('/')
          //     .filter(Boolean)
          //     .join('/')}/bidders/${bidder}/`,
          //   {
          //     state: { noScroll: true },
          //   }
          // );
        }}
      />
      <PrebidUserIdModal
        siteId={showInheritanceBadge ? params.siteId : organisation.id}
        userId={userIdInfo}
        onDismiss={() => {
          toggleModal(false);
          // navigate(location.pathname.replace(`bidders/${bidder}/`, '')
        }}
        isOpen={!!isModalOpen}
        showInheritanceBadge={showInheritanceBadge}
      />
    </>
  );
};

export default PrebidUserIdCard;
