import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeployedStatus from './DeployedStatus';

const ReleaseRow = ({ release, onDeploy }) => {
  return (
    <tr>
      <td>
        <a href="#" className="text-default">
          <div className="font-weight-semibold">{release.version}</div>
        </a>
      </td>
      <td className="text-center">
        <div className="font-size-sm text-muted line-height-1">{release.created}</div>
      </td>
      <td>
        <div>
          <span className="text-default font-weight-semibold letter-icon-title">
            {release.user.displayName}
          </span>
        </div>
      </td>
      <td className="text-center">
        {release.type == 'user' ? (
          <span className="badge bg-success-400">user</span>
        ) : (
          <span className="badge bg-info-400">system</span>
        )}
      </td>
      <td>
        <a href="#" className="text-default">
          <div className="font-weight-semibold">
            {release.title}
            <div
              className="text-muted font-size-sm"
              style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            >
              {release.description}
            </div>
          </div>
        </a>
      </td>
      <td>
        <div className="justify-content-center">
          <DeployedStatus
            production={release.production}
            staging={release.staging}
            build={release.status}
          />
        </div>
      </td>
      <td>
        <button
          type="button"
          disabled={
            release.status !== 'success' ||
            (release.staging && release.staging.status !== 'failure')
          }
          className="btn btn-outline-warning mr-2"
          onClick={() => onDeploy(release, 'staging')}
        >
          <FontAwesomeIcon icon={['fas', 'rocket']} className="mr-2" /> Deploy to Staging
        </button>
        <button
          type="button"
          disabled={
            release.status !== 'success' ||
            (release.production && release.production.status !== 'failure')
          }
          className="btn btn-outline-primary"
          onClick={() => onDeploy(release, 'production')}
        >
          <FontAwesomeIcon icon={['fas', 'rocket']} className="mr-2" /> Deploy to Production
        </button>
      </td>
      <td className="text-center">
        <a href="#" className="btn btn-link btn-float text-default">
          <FontAwesomeIcon icon={['fas', 'history']} />
        </a>
      </td>
    </tr>
  );
};

ReleaseRow.defaultProps = {
  created: 'Timestamp',
  user: 'Default User',
  type: 'user',
  version: 'Version 0',
};

export default ReleaseRow;
