import React, { useState, useEffect } from 'react';
// import { navigate, useLocation, useParams, useMatch } from '@reach/router';
import { useParams } from '@reach/router';
import { useAppContext } from '../../../providers/App';
import PluginCard from './PluginCard';
import PrebidBidderModal from './PrebidBidderModal';

const PrebidBidderCard = ({ bidder, isEnabled, showInheritanceBadge }) => {
  const { organisation } = useAppContext();
  const [bidderInfo, setBidderInfo] = useState();
  const params = useParams();
  // const location = useLocation();
  // const matchesUrl = useMatch(`bidders/${bidderInfo?.id}`);
  // const isModalOpen = !!(bidderInfo?.id && matchesUrl);
  const [isModalOpen, toggleModal] = useState(false);

  useEffect(() => {
    import(
      /* webpackExports ["id", "name", "image", "description"] */
      /* webpackChunkName: "bidders/[request]" */
      `../forms/bidders/${bidder}`
    ).then(info => {
      setBidderInfo(info);
    });
  }, [bidder]);

  if (!bidderInfo) {
    return null;
  }

  return (
    <>
      <PluginCard
        name={bidderInfo.name}
        image={bidderInfo.image}
        description={bidderInfo.description}
        enabled={isEnabled}
        openSettings={() => {
          toggleModal(true);
          // navigate(
          //   `/${location.pathname
          //     .split('/')
          //     .filter(Boolean)
          //     .join('/')}/bidders/${bidder}/`,
          //   {
          //     state: { noScroll: true },
          //   }
          // );
        }}
      />
      <PrebidBidderModal
        siteId={showInheritanceBadge ? params.siteId : organisation.id}
        bidder={bidderInfo}
        onDismiss={() => {
          toggleModal(false);
          // navigate(location.pathname.replace(`bidders/${bidder}/`, '')
        }}
        isOpen={!!isModalOpen}
        showInheritanceBadge={showInheritanceBadge}
      />
    </>
  );
};

export default PrebidBidderCard;
