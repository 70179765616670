import React from 'react';
import { Link } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Menu = ({ data }) => {
  const menu = [];

  for (let i = 0; i < data.length; i++) {
    const { title, icon, items } = data[i];

    menu.push(
      <div className="breadcrumb-elements-item dropdown p-0" key={title}>
        <a
          href="#"
          className="breadcrumb-elements-item dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <FontAwesomeIcon icon={['fas', icon]} className="mr-2" />
          {title}
        </a>

        <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end">
          {items.map(subItem => (
            <button className="dropdown-item" key={subItem.title} onClick={subItem.onClick}>
              {subItem.icon && <FontAwesomeIcon icon={subItem.icon} className="mr-3" />}
              {subItem.title}
            </button>
          ))}
        </div>
      </div>
    );
  }

  return menu;
};

const Breadcrumb = ({ items = [], menu }) => {
  return (
    <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
      <div className="d-flex">
        <div className="breadcrumb">
          <Link to="/" className="breadcrumb-item">
            <i className="icon-home2 mr-2" /> Home
          </Link>
          {!!items.length &&
            items.slice(0, -1).map(([name, url]) => {
              return (
                <Link key={url} to={url} className="breadcrumb-item">
                  {name}
                </Link>
              );
            })}
          {!!items.length && (
            <span className="breadcrumb-item active">{items[items.length - 1][0]}</span>
          )}
        </div>
      </div>
      <div className="header-elements d-none">
        <div className="breadcrumb justify-content-center">
          <a href="#" className="breadcrumb-elements-item">
            <i className="icon-comment-discussion mr-2"></i>
            Support
          </a>
          {menu && <Menu data={menu} />}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
