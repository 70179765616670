import React, { useState } from 'react';
import posed from 'react-pose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CardContainer,
  CardHeading,
  CardActions,
  CardTitle,
  CardContent,
} from '../../../components/Card';
import FormRow from '../../../components/Form';
import { FormikToggle as Toggle } from '../../../components/Form/Toggle';
import { FormikSelect as Select } from '../../../components/Form/Select';
import SizeMappings from './SizeMapping';
import { displayNamesToValues, createSizeTag } from './utils';

const CardAccordianContent = posed.div({
  opened: {
    height: '100%',
    opacity: 1,
  },
  closed: {
    height: '0px',
    opacity: 0,
  },
});

const ActionButton = ({ icon, ...props }) => (
  <button type="button" className="btn bg-transparent  btn-icon" {...props}>
    <FontAwesomeIcon icon={['fas', icon]} className="mr-1" size="lg" />
  </button>
);

export const schema = yup =>
  yup.object().shape({
    code: yup.string().required(),
    fallbackSizes: yup
      .array()
      .of(
        yup.object().shape({
          width: yup.number().nullable(true),
          height: yup.number().nullable(true),
          fluid: yup.boolean(),
        })
      )
      .nullable(true),
    responsiveSizes: yup
      .array()
      .of(
        yup.object().shape({
          viewportOffset: yup.number(),
          sizes: yup.array().of(
            yup.object().shape({
              width: yup.number().nullable(true),
              height: yup.number().nullable(true),
              fluid: yup.boolean(),
            })
          ),
        })
      )
      .nullable(true),
    outOfPage: yup.boolean().required(),
    interstitial: yup.boolean().required(),
    topAnchor: yup.boolean().required(),
    bottomAnchor: yup.boolean().required(),
    leftAnchor: yup.boolean().required(),
    rightAnchor: yup.boolean().required(),
  });

/**
 * @type {React.FunctionComponent<import('../../../types/settings').Format>}
 */
const Format = ({ name, value, onRemove, readOnly, isOpen: defaultOpen }) => {
  const [isOpen, toggleOpen] = useState(defaultOpen);

  const dedupedFallbackSizes = Array.from(
    new Set((value.fallbackSizes || []).map(size => size.displayName))
  );
  const fallbackSizes = dedupedFallbackSizes.join(', ');

  return (
    <CardContainer className="w-100">
      <CardHeading inline={true}>
        <CardTitle as="h5" style={{ cursor: 'pointer' }} onClick={() => toggleOpen(!isOpen)}>
          <span className="font-weight-semibold">Ad Format - </span>
          {value.code || 'New Ad Slot'}
          <span className="font-size-base text-muted ml-2 font-size-sm">{fallbackSizes}</span>
        </CardTitle>
        <CardActions>
          <ActionButton
            icon={`chevron-${isOpen ? 'up' : 'down'}`}
            onClick={() => toggleOpen(!isOpen)}
          />
          {onRemove && <ActionButton icon="times" onClick={() => onRemove()} />}
        </CardActions>
      </CardHeading>

      <CardAccordianContent pose={isOpen ? 'opened' : 'closed'} style={{ overflow: 'hidden' }}>
        <CardContent>
          <fieldset className="mb-3">
            <legend className="text-uppercase font-size-sm font-weight-bold">
              {value.code || 'New Ad format'}
            </legend>

            <FormRow
              label="Formatcode"
              name={`${name}.code`}
              value={value.code}
              disabled={readOnly}
              padding="small"
            />
            <FormRow
              as={Select}
              label="Fallback sizes"
              name={`${name}.fallbackSizes`}
              createTag={createSizeTag}
              value={dedupedFallbackSizes}
              disabled={!!value.outOfPage || readOnly}
              onChange={val => {
                return displayNamesToValues(val);
              }}
              padding="small"
            />
            <SizeMappings
              name={`${name}.responsiveSizes`}
              value={value.responsiveSizes}
              disabled={!!value.outOfPage || readOnly}
            />
            <FormRow
              label="Out of page"
              name={`${name}.outOfPage`}
              as={Toggle}
              value={true}
              checked={!!value.outOfPage}
              disabled={readOnly}
              padding="small"
            />
            <FormRow
              label="Interstitial"
              name={`${name}.interstitial`}
              as={Toggle}
              value={true}
              checked={!!value.interstitial}
              disabled={readOnly}
              padding="small"
            />
            <FormRow
              label="Top anchor"
              name={`${name}.topAnchor`}
              as={Toggle}
              value={true}
              checked={!!value.topAnchor}
              disabled={readOnly}
              padding="small"
            />
            <FormRow
              label="Bottom anchor"
              name={`${name}.bottomAnchor`}
              as={Toggle}
              value={true}
              checked={!!value.bottomAnchor}
              disabled={readOnly}
              padding="small"
            />
            <FormRow
              label="Left anchor"
              name={`${name}.leftAnchor`}
              as={Toggle}
              value={true}
              checked={!!value.leftAnchor}
              disabled={readOnly}
              padding="small"
            />
            <FormRow
              label="Right anchor"
              name={`${name}.rightAnchor`}
              as={Toggle}
              value={true}
              checked={!!value.rightAnchor}
              disabled={readOnly}
              padding="small"
            />
          </fieldset>
        </CardContent>
      </CardAccordianContent>
    </CardContainer>
  );
};

export default React.memo(Format);
