import React from 'react';
import { Formik, Field } from 'formik';
import Button from '../Button';
import SaveButton from '../Form/SaveButton';
import FormRow from '../../components/Form/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalTitle, ModalContainer, ModalActions, ModalHeading } from '../Modal';

/**
 * @type {React.Component<*>}
 */
const AdRefreshModal = ({ name, value, onDismiss }) => {
  return (
    <Field>
      {({ form }) => (
        <Formik
          initialValues={value}
          onSubmit={(values, actions) => {
            form.setFieldValue(name, values);
            actions.setSubmitting(false);
            onDismiss();
          }}
        >
          {({ values, handleSubmit, isSubmitting }) => {
            return (
              <ModalContainer
                className="w-75"
                style={{ marginTop: '15vh' }}
                aria-label={`${value.format} Mapping`}
              >
                <ModalHeading inline>
                  <ModalTitle onDismiss={onDismiss}>
                    <span className="font-weight-semibold">{value.format}</span> - Mapping
                  </ModalTitle>

                  <ModalActions>
                    <Button type="button" theme="dark" variation="outline" className="btn-icon">
                      <FontAwesomeIcon
                        icon={['far', 'save']}
                        className="mr-1"
                        size="lg"
                        onClick={handleSubmit}
                      />{' '}
                      Save
                    </Button>
                    <Button
                      theme="dark"
                      variation="outline"
                      className="btn-icon"
                      onClick={onDismiss}
                    >
                      <FontAwesomeIcon icon={['fas', 'times']} className="mr-1" size="lg" /> Close
                    </Button>
                  </ModalActions>
                </ModalHeading>
                <div className="modal-body">
                  <FormRow label="Format" name="format" value={values.format} />
                  <FormRow label="Slot" name="slot" value={values.slot} />
                  <FormRow label="Seconds" name="seconds" value={values.seconds} />

                  <div className="modal-footer">
                    <button type="button" className="btn btn-link" onClick={onDismiss}>
                      Close
                    </button>
                    <SaveButton onClick={handleSubmit} isLoading={isSubmitting} />
                  </div>
                </div>
              </ModalContainer>
            );
          }}
        </Formik>
      )}
    </Field>
  );
};

export default AdRefreshModal;
