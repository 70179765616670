import { validateYupSchema, yupToFormErrors } from 'formik';

export function asyncYupValidation(values, schema, context) {
  return validateYupSchema(values, schema, false, context)
    .then(() => [])
    .catch(err => {
      // Yup will throw a validation error if validation fails. We catch those and
      // resolve them into Formik errors. We can sniff if something is a Yup error
      // by checking error.name.
      // @see https://github.com/jquense/yup#validationerrorerrors-string--arraystring-value-any-path-string
      if (err.name === 'ValidationError') {
        return yupToFormErrors(err);
      }
      throw err;
    });
}
