import React from 'react';
import classNames from 'classnames';
import { CardContainer, CardHeading, CardTitle, CardContent } from '../../../components/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * @typedef {object} PluginModalProps
 * @property {string} name
 * @property {string} image
 * @property {string} description
 * @property {boolean} enabled
 * @property {Function} openSettings
 */
/**
 * @type {React.FunctionComponent<PluginModalProps>}
 */
const PluginCard = function PluginCard({ name, image, description, enabled, openSettings }) {
  return (
    <CardContainer className="text-center">
      <CardHeading>
        <img src={image} width="110" height="110" />
        <CardTitle as="h5">{name}</CardTitle>
      </CardHeading>

      <CardContent>
        <div style={{ height: '120px' }}>
          <span className="mb-3">
            <p
              className="card-text"
              style={{
                WebkitLineClamp: 7,
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {description}
            </p>
          </span>
        </div>

        <div>
          <div className={classNames('mb-4', { 'text-success': enabled, 'text-danger': !enabled })}>
            <b>
              <FontAwesomeIcon
                icon={['fas', enabled ? 'check-circle' : 'times-circle']}
                className="mr-1"
              />
            </b>
            {enabled ? `Enabled` : `Disabled`}
          </div>
          <div>
            <button
              type="button"
              className="btn btn-primary btn-labeled btn-labeled-left"
              onClick={openSettings}
            >
              <b>
                <FontAwesomeIcon icon={['fas', 'cogs']} />
              </b>
              Settings
            </button>
          </div>
        </div>
      </CardContent>
    </CardContainer>
  );
};

export default PluginCard;
