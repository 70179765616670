import React from 'react';
import Noty from 'noty';

import { useAppContext } from '../../providers/App';
import PageTitle from '../../components/PageTitle';
// import BrandTitle from '../../components/BrandTitle';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Breadcrumb from '../../components/Breadcrumb';
import Loader from '../../components/Loader';
import { useQuery, useMutation, gql } from '@apollo/client';
import ReleaseSidebar from '../../screens/releases/Sidebar';
import { useEffectWhenPageIsActive } from '@utils/useEffectWhenPageIsActive';
import { Helmet } from 'react-helmet';

import ReleaseScreen from '../../screens/releases';

const POLL_INTERVAL = 1000 * 5;

Noty.overrideDefaults({
  theme: 'limitless',
  layout: 'topRight',
  type: 'alert',
  timeout: 2500,
});

const RELEASE_FRAGMENT = gql`
  fragment ReleaseFragment on Release {
    id
    title
    description
    created(format: "DD MMM YYYY HH:mm")
    version
    type
    user {
      displayName
    }
    status
    staging: deployment(env: staging) {
      status
      created(format: "DD MMM YYYY HH:mm")
    }
    production: deployment(env: production) {
      status
      created(format: "DD MMM YYYY HH:mm")
    }
  }
`;

const GET_RELEASES = gql`
  query getReleases($siteId: ID!) {
    site(siteId: $siteId) {
      id
      name
      logo
      releases {
        ...ReleaseFragment
      }
    }
  }
  ${RELEASE_FRAGMENT}
`;

// const RELEASE_SUBSCRIPTION = gql`
//   subscription getReleaseUpdates($site: String!) {
//     onRelease(site: $site) {
//       ...ReleaseFragment
//     }
//   }
//   ${RELEASE_FRAGMENT}
// `;

const DEPLOY_RELEASE = gql`
  mutation deployRelease($site: String!, $env: Environment!, $release: String!) {
    deployRelease(site: $site, environment: $env, release: $release) {
      __typename
      id
      environment
      status
      created(format: "DD MMM YYYY HH:mm")
      release {
        id
      }
    }
  }
`;

export default function Releases({ siteId }) {
  const { organisation } = useAppContext();
  const site = organisation.sites.find(site => site.id === siteId);
  const { loading, data, refetch } = useQuery(GET_RELEASES, {
    variables: { siteId },
  });
  const [deployRelease] = useMutation(DEPLOY_RELEASE, {
    update(cache, mutationRes) {
      const deployment = mutationRes.data.deployRelease;

      const data = cache.readQuery({
        query: GET_RELEASES,
        variables: { siteId },
      });

      const releaseIndex = data.site.releases.findIndex(release => {
        return release.id === deployment.release.id;
      });

      if (releaseIndex > -1) {
        const releases = [...data.site.releases];
        releases[releaseIndex] = {
          ...releases[releaseIndex],
          [deployment.environment]: {
            ...deployment,
            ...releases[releaseIndex][deployment.environment],
          },
        };

        cache.writeQuery({
          query: GET_RELEASES,
          data: {
            ...data,
            site: {
              ...data.site,
              releases,
            },
          },
        });
      }

      setTimeout(() => {
        refetch();
      }, 10000);
    },
  });

  useEffectWhenPageIsActive(() => {
    let timeoutTimer;
    function refetchInterval() {
      refetch();

      timeoutTimer = setTimeout(refetchInterval, POLL_INTERVAL);
    }

    refetchInterval();

    return () => {
      if (timeoutTimer) {
        clearTimeout(timeoutTimer);
      }
    };
  }, [refetch]);

  // useEffect(() => {
  //   if (subscribeToMore) {
  // subscribeToMore({
  //   variables: { site: siteId },
  //   document: RELEASE_SUBSCRIPTION,
  //   updateQuery: (prev, { subscriptionData }) => {
  //     let releases = [];
  //     if (prev?.site?.releases) {
  //       const releaseIndex = prev.site.releases.findIndex(release => {
  //         return release.id === subscriptionData.data.onRelease.id;
  //       });
  //       releases = [...prev.site.releases];
  //       if (releaseIndex > -1) {
  //         releases[releaseIndex] = subscriptionData.data.onRelease;
  //       } else {
  //         releases.unshift(subscriptionData.data.onRelease);
  //       }
  //     }
  //     return {
  //       ...prev,
  //       site: {
  //         ...prev.site,
  //         releases: releases,
  //       },
  //     };
  //   },
  // });
  //   }
  // }, [subscribeToMore]);

  const deploy = async (release, env) => {
    try {
      await deployRelease({ variables: { site: siteId, release: release.id, env } });

      new Noty({
        text: `Deployment of ${release.title} (v${release.version})`,
        type: 'success',
      }).show();
    } catch (err) {
      new Noty({
        text: `Deployment failed (v${release.version})`,
        type: 'error',
      }).show();
    }
  };

  return (
    <>
      <ReleaseSidebar sites={organisation.sites} />
      <div className="content-wrapper">
        <div className="page-header page-header-light">
          <Helmet title={`${site.name}'s Releases`} />
          <PageTitle title="Releases" />

          <Breadcrumb items={[['Releases', '/releases'], [site.name]]} />
        </div>

        <div className="content">
          {loading && (
            <div style={{ height: '300px', position: 'relative' }}>
              <Loader />
            </div>
          )}

          {data && (
            <ReleaseScreen
              key={data.site}
              site={data.site}
              releases={data.site?.releases ?? []}
              onDeploy={deploy}
            />
          )}
        </div>
      </div>
    </>
  );
}
