import React from 'react';
import FormRow from '../../../../components/Form';
import { settingsRequiredWhenEnabled } from '../../../../utils';
import { gql } from '@apollo/client';
import logo from '../../../../assets/images/plugins/botprotection.png';
import { FormikSelect as Select } from '../../../../components/Form/Select';

export const id = 'botprotection';
export const name = 'Bot protection';
export const description = 'Protect yourself from bots by blocking them from running your ads.';
export const image = logo;

export const genSchema = yup =>
  yup.object().shape({
    blocklist: settingsRequiredWhenEnabled(yup.array().of(yup.string()).label('Blocklist')),
  });

function createTag({ term }) {
  if (
    !/(\b25[0-5]|\b2[0-4][0-9]|\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/.test(
      term
    )
  ) {
    return null;
  }

  console.log('createTag', term);

  return { id: term, text: term, newTag: true };
}

/**
 * @type {import('../../../../../types/settings').FormSection<{ blocklist: Array<string> }>}
 */
export const SettingsForm = ({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) => {
  return (
    <>
      <FormRow
        as={Select}
        name="blocklist"
        label="IP blocklist"
        value={value.blocklist}
        inheritedValue={inheritedValue.blocklist.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors['blocklist']}
        createTag={createTag}
      />
    </>
  );
};

export const FRAGMENT_PLUGIN_SETTINGS = {
  name: 'BotprotectionPluginSettings',
  fragment: gql`
    fragment BotprotectionPluginSettings on BotprotectionPlugin {
      blocklist {
        value
        inheritedValue
      }
    }
  `,
};
