import React from 'react';
import { connect } from 'formik';
import classNames from 'classnames';

/**
 * @type {React.SFC<{ className: string, type: string, name: string }>}
 */
const Textarea = ({ className, name, value = '', rows = 3, cols = 3, ...props }) => {
  return (
    <textarea
      name={name}
      rows={rows}
      cols={cols}
      value={value}
      className={classNames('form-control', className)}
      {...props}
    />
  );
};

export const FormikTextarea = connect(({ formik, name, onChange, onBlur, ...props }) => {
  let onChangeHandler;
  let onBlurHandler;
  if (onChange) {
    onChangeHandler = e => formik.setFieldValue(name, onChange(e.value));
  }
  if (onBlur) {
    onBlurHandler = e => {
      formik.setFieldTouched(name);
      onBlur(e);
    };
  }
  onChangeHandler = onChangeHandler || formik.handleChange;
  onBlurHandler = onBlurHandler || formik.handleBlur;

  return <Textarea name={name} onBlur={onBlurHandler} onChange={onChangeHandler} {...props} />;
});

export default Textarea;
