import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * @typedef DeploymentData
 * @property {string} created
 * @property {string} status
 */

const Status = ({ status, tooltip, env }) => {
  const tooltipRef = useRef(null);
  let icon = 'times';
  let statusClassname = {
    'text-success': status === 'success',
    'text-danger': status === 'pending',
    'text-info': !status,
  };

  if (status === 'pending') {
    icon = 'sync';
  }

  if (status === 'success') {
    icon = 'check';
  }

  useEffect(() => {
    import('jquery').then(({ default: $ }) => {
      if (tooltip) {
        $(tooltipRef.current).tooltip();
      } else {
        $(tooltipRef.current).tooltip('dispose');
      }
    });
  }, [tooltip]);

  return (
    <span
      ref={tooltipRef}
      className={classNames('font-size-sm d-table', statusClassname)}
      data-popup="tooltip"
      title={tooltip}
    >
      <FontAwesomeIcon icon={['fas', icon]} size="sm" className="mr-2" /> {env}
    </span>
  );
};

const generateStatusTooltip = env => {
  const creationDate = env.deployed;

  switch (env.status) {
    case 'failure': {
      return `Deploy failed on ${creationDate}`;
    }
    case 'success': {
      return `Deploy succeeded on ${creationDate}`;
    }
    default: {
      return null;
    }
  }
};

/**
 * @type {React.FunctionComponent<{production: DeploymentData, staging: DeploymentData, build: "pending" | "failed" | "success"}>}
 */
const DeployedStatus = ({ production, staging, build }) => {
  let productionTooltip = '';
  let stagingTooltip = '';
  if (production) {
    productionTooltip = generateStatusTooltip(production);
  }
  if (staging) {
    stagingTooltip = generateStatusTooltip(staging);
  }

  let tooltip = '';
  if (build === 'success') {
    tooltip = 'Package is ready to deploy.';
  }
  if (build === 'failed') {
    tooltip = 'Package failed to create. Please contact an adshim representative.';
  }

  return (
    <>
      <Status env="Release ready" status={build} tooltip={tooltip} />
      <Status
        env="Production"
        status={production && production.status}
        tooltip={productionTooltip}
      />
      <Status env="Staging" status={staging && staging.status} tooltip={stagingTooltip} />
      {/* <ReactTooltip place="right" type="dark" effect="solid" /> */}
    </>
  );
};

export default DeployedStatus;
