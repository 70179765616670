import React from 'react';
import { gql } from '@apollo/client';
import FormRow from '../../../../components/Form';
import MappingTable from '../../../../components/Form/MappingTable';
import logo from '../../../../assets/images/bidders/proxistore.jpg';
import { settingsRequiredWhenEnabled } from '../../../../utils';

export const id = 'proxistore';
export const name = 'Proxistore';
export const mutationId = name;
export const description =
  'Proxistore is the first programmatic platform for localized display advertising! Find out what Proxistore can do for you!';
export const image = logo;

export const genSchema = yup =>
  yup
    .object()
    .default(undefined)
    .shape({
      website: settingsRequiredWhenEnabled(
        yup.array().of(
          yup.object().shape({
            targeting: yup.array().of(
              yup.object().shape({
                key: yup.string(),
                value: yup.mixed(),
              })
            ),
            name: yup.string(),
            value: yup.mixed(),
          })
        )
      ),
      language: settingsRequiredWhenEnabled(
        yup.array().of(
          yup.object().shape({
            targeting: yup.array().of(
              yup.object().shape({
                key: yup.string(),
                value: yup.mixed(),
              })
            ),
            name: yup.string(),
            value: yup.mixed(),
          })
        )
      ),
    });

/**
 * @type {import('../../../../../types/settings').FormSection<{}>}
 */
export const SettingsForm = function CriteoSettingsForm({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) {
  return (
    <>
      <FormRow
        name="language"
        label="Language"
        value={value.language}
        inheritedValue={inheritedValue.language.inheritedValue ?? undefined}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors.language}
        direction="column"
        as={MappingTable}
      />
      <FormRow
        name="website"
        label="Website"
        value={value.website}
        inheritedValue={inheritedValue.website.inheritedValue ?? undefined}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors.website}
        direction="column"
        as={MappingTable}
      />
    </>
  );
};

export const FRAGMENT_BIDDER_SETTINGS = {
  name: 'ProxistoreBidderSettings',
  fragment: gql`
    fragment ProxistoreBidderSettings on ProxistoreBidder {
      language {
        value {
          name
          value
          targeting {
            key
            value
          }
        }
        inheritedValue {
          name
          value
          targeting {
            key
            value
          }
        }
      }
      website {
        value {
          name
          value
          targeting {
            key
            value
          }
        }
        inheritedValue {
          name
          value
          targeting {
            key
            value
          }
        }
      }
    }
  `,
};
