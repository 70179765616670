export const getCurrentValue = (value, inheritedValue, prop) => {
  if (value && Object.prototype.hasOwnProperty.call(value, prop)) {
    return value[prop];
  }

  return inheritedValue && inheritedValue[prop];
};

export const isEnabled = (value, inheritedValue) => {
  if (typeof value.enabled !== 'undefined') {
    return value.enabled;
  }

  return !!inheritedValue.enabled.inheritedValue;
};
