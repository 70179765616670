import React, { useState, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MappingModal from './MappingModal';

import { Field, FieldArray } from 'formik';

const TableRow = ({ name, targeting = [], value, disabled, readonly, onEdit, onRemove }) => {
  const keyValuesString = targeting
    .reduce((prev, keyval) => {
      return prev.concat(`${keyval.key}=${keyval.value}`);
    }, [])
    .filter(Boolean);

  return (
    <tr style={{ opacity: readonly ? '0.5' : '1' }}>
      <td style={{ width: '150px' }} data-tip={name}>
        {name}
      </td>
      <td style={{ width: '250px' }} data-tip={keyValuesString.join('<br />')}>
        {keyValuesString.join('&')}
      </td>
      <td style={{ width: '100px', textAlign: 'center' }} data-tip={value}>
        {value}
      </td>
      <td style={{ width: '60px', textAlign: 'center' }}>
        {onEdit && (
          <button
            type="button"
            disabled={disabled}
            className="btn btn-outline bg-primary text-primary-800 btn-icon"
            onClick={() => onEdit()}
          >
            <FontAwesomeIcon icon={['far', 'edit']} size="lg" />
          </button>
        )}
      </td>
      <td style={{ width: '70px', textAlign: 'center' }}>
        {onRemove && (
          <button
            type="button"
            disabled={disabled}
            className="btn btn-outline bg-danger text-danger-800 btn-icon"
            onClick={() => onRemove()}
          >
            <FontAwesomeIcon icon={['far', 'trash-alt']} size="lg" />
          </button>
        )}
      </td>
    </tr>
  );
};

function MappingTable({ name, disabled, value = [], placeholder = [] }) {
  const showInheritValue = useRef(!value || !value.length);
  const [editScreen, setOpenEditScreen] = useState(null);

  return (
    <Field name={name}>
      {({ field }) => {
        let { value } = field;
        value = value || [];

        let selectedMappingRow;
        if (editScreen !== null) {
          selectedMappingRow = value[editScreen];
        }

        if (!showInheritValue.current && (!value || !value.length)) {
          showInheritValue.current = true;
        }

        return (
          <FieldArray name={name}>
            {({ push, remove }) => {
              return (
                <>
                  <ReactTooltip
                    place="top"
                    type="info"
                    effect="solid"
                    delayShow={200}
                    html={true}
                  />
                  <table className="table table-striped mapping-table mb-2">
                    <thead>
                      <tr>
                        <th style={{ width: '150px' }}>Name</th>
                        <th style={{ width: '250px' }}>Key Values</th>
                        <th style={{ width: '100px', textAlign: 'center' }}>Mapping</th>
                        <th style={{ width: '60px', textAlign: 'center' }}>Edit</th>
                        <th style={{ width: '70px', textAlign: 'center' }}>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {value.map((mapping, index) => (
                        <TableRow
                          {...mapping}
                          key={index}
                          disabled={disabled}
                          onEdit={() => setOpenEditScreen(index)}
                          onRemove={() => remove(index)}
                        />
                      ))}
                      {showInheritValue.current &&
                        placeholder.map((mapping, index) => (
                          <TableRow {...mapping} key={index} readonly={true} disabled={disabled} />
                        ))}
                    </tbody>
                  </table>
                  <button
                    type="button"
                    disabled={disabled}
                    className="btn bg-blue btn-labeled btn-labeled-right ml-auto mr-2"
                    onClick={() => {
                      showInheritValue.current = false;
                      push({
                        name: 'New Mapping',
                        value: '',
                        targeting: [],
                      });
                    }}
                  >
                    <b>
                      <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                    </b>{' '}
                    Add New Mapping
                  </button>

                  {selectedMappingRow && (
                    <MappingModal
                      name={`${name}.${editScreen}`}
                      value={selectedMappingRow}
                      onDismiss={() => setOpenEditScreen(null)}
                    />
                  )}
                </>
              );
            }}
          </FieldArray>
        );
      }}
    </Field>
  );
}

export default MappingTable;
