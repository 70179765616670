import React from 'react';
import { Helmet } from 'react-helmet';
import Logo from '../components/Logo';

export function LoadingScreen() {
  return (
    <div className="page-content">
      <Helmet>
        <title>Loading - Adshim Portal</title>
      </Helmet>

      <div className="content-wrapper">
        <div className="content d-flex justify-content-center align-items-center flex-column">
          <div className="text-primary btn bg-transparent btn-icon mb-2">
            <Logo width="40px" height="40px" />
          </div>
          <div className="text-center">Loading...</div>
        </div>
      </div>
    </div>
  );
}
