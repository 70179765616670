import React from 'react';

const Loader = () => {
  return (
    <div
      className="theme_radar"
      style={{ left: '50%', right: '50%', transform: 'translate3d(-50%,-50%,-50%)' }}
    >
      <div
        className="pace_activity"
        style={{ borderTopColor: '#000', borderBottomColor: '#000' }}
      />
    </div>
  );
};

export default Loader;
