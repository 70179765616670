import React, { useEffect, useState } from 'react';
import { useMutation, useApolloClient, gql } from '@apollo/client';
import Noty from 'noty';
import { ModalContainer, ModalHeading, ModalActions, ModalTitle } from '../../../components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import SaveButton from '../../../components/Form/SaveButton';
import Button from '../../../components/Button';
import FormRow from '../../../components/Form';
import { FormikToggle as Toggle } from '../../../components/Form/Toggle';
import { asyncYupValidation } from '@utils/asyncYupValidation';
import { useDynamicQuery } from '@utils/useDynamicQuery';
import { convertUntouchedToNull } from '@utils/convertUntouchedToNull';
import { fillFieldsWithDefaultNull } from '@utils/index';
import { getYup } from '@utils/getYup';
import { isEnabled as isEnabledUtil } from '../utils';
import { klona } from 'klona/json';

async function buildSchema(genSchema) {
  const yup = await getYup();
  const schema = genSchema(yup);

  return schema.shape({
    enabled: yup.boolean(),
  });
}

function PluginModalContent({ client, siteId, plugin, onDismiss, showInheritanceBadge }) {
  const { called, loading, result, executeQuery } = useDynamicQuery(client);
  const [pluginFormSettings, setPluginFormSettings] = useState();

  useEffect(() => {
    import(
      /* webpackExports ["genSchema", "SettingsForm", "FRAGMENT_PLUGIN_SETTINGS"] */
      /* webpackChunkName: "plugins/[request]" */
      `../forms/plugins/${plugin.id}`
    ).then(info => {
      setPluginFormSettings({
        genSchema: info.genSchema,
        SettingsForm: info.SettingsForm,
      });

      const GET_PLUGIN_QUERY = gql`
        query getSiteSettings($id: ID!, $plugin: AvailablePlugins!, $type: SettingsType!) {
          pluginSettings: pluginSettings(id: $id, plugin: $plugin, type: $type) {
            enabled {
              value
              inheritedValue
            }
            ...${info.FRAGMENT_PLUGIN_SETTINGS.name}
          }
        }
        ${info.FRAGMENT_PLUGIN_SETTINGS.fragment}
      `;

      executeQuery(GET_PLUGIN_QUERY, {
        variables: {
          id: siteId,
          plugin: plugin.id,
          type: showInheritanceBadge ? 'SITE' : 'ORGANISATION',
        },
        fetchPolicy: 'network-only',
      });
    });
  }, []);

  if (!called || loading || !result) {
    return <div></div>;
  }

  const pluginValue = {};
  Object.keys(result.data.pluginSettings).forEach(key => {
    pluginValue[key] = result.data.pluginSettings[key].value;
  });

  return (
    <PluginForm
      siteId={siteId}
      plugin={plugin}
      value={convertUntouchedToNull(pluginValue)}
      inheritedValue={result.data.pluginSettings}
      showInheritanceBadge={showInheritanceBadge}
      onDismiss={onDismiss}
      formComponent={pluginFormSettings.SettingsForm}
      validationSchema={pluginFormSettings.genSchema}
    />
  );
}

function PluginForm({
  siteId,
  plugin,
  value = {},
  inheritedValue,
  onDismiss,
  showInheritanceBadge,
  formComponent: FormComponent,
  validationSchema,
}) {
  const pluginId = plugin.id[0].toUpperCase() + plugin.id.slice(1).replace(/\s/g, '');
  const [saveSettings, { loading }] = useMutation(gql`
    mutation saveSettings($siteId: ID!, $type: SettingsType!, $settings: ${pluginId}Input!) {
      update${pluginId}Settings(id: $siteId, type: $type, input: $settings)
    }
  `);

  return (
    <Formik
      initialValues={value}
      validateOnChange={true}
      validateOnBlur={true}
      validate={async values => {
        const schema = await buildSchema(validationSchema);
        return asyncYupValidation(values, schema, {
          inheritedData: inheritedValue,
        });
      }}
      onSubmit={async values => {
        const schema = await buildSchema(validationSchema);
        const sanitizedValues = await schema.validate(klona(values), {
          stripUnknown: true,
        });

        const toast = new Noty({
          text: `Saving ${plugin.name} settings`,
          type: 'info',
        }).show();

        try {
          await saveSettings({
            variables: {
              siteId,
              type: showInheritanceBadge ? 'SITE' : 'ORGANISATION',
              settings: fillFieldsWithDefaultNull(sanitizedValues, schema),
            },
          });

          toast.setText(`${plugin.name} settings succesfully saved.`);
          toast.setType('success');

          onDismiss();
        } catch (err) {
          toast.setText(`Something went wrong saving ${plugin.name} settings.`);
          toast.setType('error');
        }
      }}
    >
      {({ values, isSubmitting, handleSubmit, errors, touched = {}, submitCount }) => {
        const isEnabled = isEnabledUtil(values, inheritedValue);
        const touchedErrors = {};
        for (const field in errors) {
          if (touched[field] || submitCount > 0) {
            touchedErrors[field] = errors[field];
          }
        }

        return (
          <>
            <ModalHeading inline style={{ borderBottom: '1px solid #ddd' }}>
              <ModalTitle onDismiss={onDismiss}>
                <span className="font-weight-semibold">{plugin.name}</span> - Plugin
              </ModalTitle>
              <ModalActions>
                <Button
                  theme="dark"
                  variation="outline"
                  className="btn-icon"
                  onClick={handleSubmit}
                >
                  <FontAwesomeIcon icon={['far', 'save']} className="mr-1" size="lg" /> Save
                </Button>
                <Button theme="dark" variation="outline" className="btn-icon" onClick={onDismiss}>
                  <FontAwesomeIcon icon={['fas', 'times']} className="mr-1" size="lg" /> Close
                </Button>
              </ModalActions>
            </ModalHeading>
            <div className="modal-body">
              <div className="row" style={{ borderBottom: '1px solid #ddd' }}>
                <div className="col-md-2 text-center">
                  <img className="mb-3" src={plugin.image} width="110" height="110" />
                </div>
                <div className="col-md-8">
                  <h6>Description</h6>
                  <p className="mb-4">{plugin.description}</p>
                  <FormRow
                    as={Toggle}
                    name="enabled"
                    label="Enabled"
                    value={true}
                    checked={values.enabled}
                    inheritedChecked={inheritedValue.enabled.inheritedValue}
                    showInheritanceBadge={showInheritanceBadge}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <h6>Settings</h6>
                  <div>
                    {FormComponent && (
                      <FormComponent
                        isEnabled={isEnabled}
                        value={values}
                        inheritedValue={inheritedValue}
                        showInheritanceBadge={showInheritanceBadge}
                        errors={touchedErrors}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Button variation="link" onClick={onDismiss}>
                Close
              </Button>
              <SaveButton onClick={handleSubmit} isLoading={isSubmitting || loading} />
            </div>
          </>
        );
      }}
    </Formik>
  );
}

const PluginModal = ({ siteId, plugin, onDismiss, isOpen, showInheritanceBadge }) => {
  const client = useApolloClient();

  return (
    <ModalContainer isOpen={isOpen} aria-label={`${plugin.name} options`}>
      <PluginModalContent
        client={client}
        siteId={siteId}
        plugin={plugin}
        onDismiss={onDismiss}
        showInheritanceBadge={showInheritanceBadge}
      />
    </ModalContainer>
  );
};

export default PluginModal;
