import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';

/**
 * @type {React.FunctionComponent<{menu: boolean, icon: import('@fortawesome/fontawesome-svg-core').IconProp, onClick: function}>}
 */
const FloatingButton = ({ menu, icon, onClick }) => {
  const Icon = () => (
    <FontAwesomeIcon icon={icon} className={menu ? 'fab-icon-open' : ''} size="lg" />
  );
  const Button = () => {
    if (menu) {
      return (
        <button
          type="button"
          className="fab-menu-btn btn bg-green btn-float rounded-round btn-icon"
          onClick={() => onClick()}
        >
          <Icon />
        </button>
      );
    } else {
      return (
        <a
          href="#"
          className="fab-menu-btn btn btn-success btn-float rounded-round btn-icon"
          onClick={e => {
            e.preventDefault();
            onClick();
          }}
        >
          <Icon />
        </a>
      );
    }
  };
  return (
    <>
      <Helmet>
        <script src={withPrefix('assets/template/js/fab.min.js')} />
      </Helmet>
      <ul className="fab-menu fab-menu-fixed fab-menu-bottom-right" data-fab-toggle="click">
        <li>
          <Button />
        </li>
      </ul>
    </>
  );
};

export default FloatingButton;
