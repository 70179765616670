import React from 'react';
import FormRow from '../../../../components/Form';
import MappingTable from '../../../../components/Form/MappingTable';
import { settingsRequiredWhenEnabled } from '../../../../utils';
import { FormikSelect as Select } from '../../../../components/Form/Select';
import { gql } from '@apollo/client';
import logo from '../../../../assets/images/bidders/gumgum.jpg';

export const id = 'gumgum';
export const name = 'GumGum';
export const mutationId = 'Gumgum';
export const description = 'GumGum is the Leading Platform for Programmatic Rich Media';
export const image = logo;

function createTag({ term }) {
  return {
    id: term,
    text: term,
  };
}

export const genSchema = Yup =>
  Yup.object()
    .default(undefined)
    .shape({
      zone: settingsRequiredWhenEnabled(
        Yup.array()
          .of(
            Yup.object().shape({
              targeting: Yup.array().of(
                Yup.object().shape({
                  key: Yup.string(),
                  value: Yup.mixed(),
                })
              ),
              name: Yup.string(),
              value: Yup.mixed(),
            })
          )
          .label('Zone ID')
      ),
      pubId: Yup.number().label('Pub ID'),
      skinSlots: Yup.array(Yup.string()).label('Slots for skins'),
    });

/**
 * @type {import('../../../../../types/settings').FormSection<{}>}
 */
export const SettingsForm = function GumGumSettingsForm({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) {
  return (
    <>
      <FormRow
        name="zone"
        label="Zone ID"
        value={value.zone}
        inheritedValue={inheritedValue.zone.inheritedValue ?? undefined}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors.zone}
        direction="column"
        as={MappingTable}
      />
      <FormRow
        name="pubId"
        label="Pub id"
        value={value.pubId}
        inheritedValue={inheritedValue.pubId.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        error={errors.pubId}
        disabled={!isEnabled}
      />
      <FormRow
        as={Select}
        name="skinSlots"
        label="Slots for skins"
        value={value.skinSlots}
        inheritedValue={inheritedValue.skinSlots.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors['skinSlots']}
        createTag={createTag}
        multiple
      />
    </>
  );
};

export const FRAGMENT_BIDDER_SETTINGS = {
  name: 'GumgumBidderSettings',
  fragment: gql`
    fragment GumgumBidderSettings on GumgumBidder {
      zone {
        value {
          name
          value
          targeting {
            key
            value
          }
        }
        inheritedValue {
          name
          value
          targeting {
            key
            value
          }
        }
      }
      pubId {
        value
        inheritedValue
      }
      skinSlots {
        value
        inheritedValue
      }
    }
  `,
};
