import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client';
// import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
// import { WebSocketLink } from '@apollo/client/link/ws';
// import * as SSE from 'subscriptions-transport-sse/dist/client';
import { getAuth } from './firebase.js';
import { onAuthStateChanged } from 'firebase/auth';

import generatedIntrospection from '../../graphql/introspection.json';

async function getAuthToken() {
  const auth = await getAuth();

  return new Promise(resolve => {
    onAuthStateChanged(auth, function (user) {
      if (user) {
        resolve(user.getIdToken());
      }
    });
  });
}

const authLink = setContext(async (_, { headers }) => {
  const authToken = await getAuthToken();

  return {
    headers: authToken
      ? {
          ...headers,
          Authorization: `Bearer ${authToken}`,
        }
      : headers,
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    console.group('%c GraphQL errors', { fontWeight: 'bold ' });
    graphQLErrors.map(({ message, extensions }) => {
      console.log(`Message: ${message}, Location: ${extensions ? extensions.code : ''}`);
    });
    console.groupEnd();
  }
});

function createOmitTypenameLink() {
  return new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }

    return forward(operation);
  });
}

function omitTypename(key, value) {
  return key === '__typename' ? undefined : value;
}

export const createApolloClient = () => {
  // const sseClient = new SSE.SubscriptionClient(
  //   process.env.GATSBY_GRAPHQL_ENDPOINT + 'subscriptions',
  //   {
  //     timeout: 10000,
  //     headers: async () => {
  //       const authToken = await getAuthToken();

  //       return {
  //         Authorization: `Bearer ${authToken}`,
  //       };
  //     },
  //   }
  // );

  // const SSELink = new SSE.SSELink(sseClient);

  // const wsLink = new WebSocketLink({
  //   uri: process.env.GATSBY_GRAPHQL_ENDPOINT.replace(/http(s)?:\/\//, 'ws$1://'),
  //   options: {
  //     reconnect: true,
  //     connectionParams: async () => {
  //       const authToken = await getAuthToken();
  //       return {
  //         Authorization: `Bearer ${authToken}`,
  //       };
  //     },
  //   },
  // });

  const httpLink = ApolloLink.from([
    errorLink,
    authLink,
    createOmitTypenameLink(),
    createHttpLink({
      uri: process.env.GATSBY_GRAPHQL_ENDPOINT,
    }),
  ]);

  // const splitLink = split(
  //   ({ query }) => {
  //     const definition = getMainDefinition(query);
  //     return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  //   },
  //   // wsLink,
  //   SSELink,
  //   httpLink
  // );

  return new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: generatedIntrospection.possibleTypes,
    }),
    link: httpLink,
    connectToDevTools: process.env.NODE_ENV === 'development',
  });
};
