import React from 'react';
import FormRow from '../../../../components/Form';
import { settingsRequiredWhenEnabled } from '../../../../utils';
import { gql } from '@apollo/client';
import logo from '../../../../assets/images/plugins/grapeshot.png';

export const id = 'grapeshot';
export const name = 'Grapeshot';
export const description =
  'Contextual Intelligence, at its core, is about contextual understanding of content.';
export const image = logo;

export const genSchema = yup =>
  yup.object().shape({
    domain: settingsRequiredWhenEnabled(yup.string().label('Domain')),
  });

/**
 * @type {import('../../../../../types/settings').FormSection<{ id: string, url: string }>}
 */
export const SettingsForm = ({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) => {
  return (
    <>
      <FormRow
        name="domain"
        label="Domain"
        value={value.domain}
        inheritedValue={inheritedValue.domain.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors['domain']}
      />
    </>
  );
};

export const FRAGMENT_PLUGIN_SETTINGS = {
  name: 'GrapeshotPluginSettings',
  fragment: gql`
    fragment GrapeshotPluginSettings on GrapeshotPlugin {
      domain {
        value
        inheritedValue
      }
    }
  `,
};
